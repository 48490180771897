import {useEffect, useState} from "react";
import ValuerAmendDropdownCard from "./valuer/ValuerAmendDropdownCard";
import GenericStrCard from "./GenericStrCard";
import check from "../assets/check_small.png"
import GenericIntCard from "./GenericIntCard";
import GenericCurrencyCard from "./GenericCurrencyCard";
import property_api from "../api/property"
import GenericDropdownCard from "./GenericDropdownCard";
import close from "../assets/close_small.png"
import ButtonLoadingWheel from "./ButtonLoadingWheel";


const ManualAddressPopup = (props) => {
    const manualEvaluate = props.manualEvaluate
    const closeManualPopup = props.closeManualPopup

    const [saon, setSaon] = useState("")
    const [street, setStreet] = useState("")
    const [town, setTown] = useState("")
    const [county, setCounty] = useState("")
    const [postcode, setPostcode] = useState("")
    const [purpose, setPurpose] = useState({id: 0, name: "Select purpose"})

    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false)
    const [bedrooms, setBedrooms] = useState()
    const [bathrooms, setBathrooms] = useState()
    const [livingRooms, setLivingRooms] = useState()
    const [kitchens, setKitchens] = useState()
    const [size, setSize] = useState()
    const [extraDetails, setExtraDetails] = useState()
    const [detailsCounter, setDetailsCounter] = useState(0)
    const [rent, setRent] = useState()

    const [submitted, setSubmitted] = useState(false)
    const [postcodeError, setPostcodeError] = useState("")
    const [postcodeValidity, setPostcodeValidity] = useState(false)
    const [processing, setProcessing] = useState(false)

    const purpose_options = [
        {id: 1, name: "Secured Lending"},
        {id: 3, name: "Internal reference"},
        {id: 4, name: "Accounting"}
    ]

    const changeValue = (title, value) => {
        if (!title) return;
        switch (title) {
            case "Flat number, house number or name*":
                setSaon(value)
                break;
            case "Street name*":
                setStreet(value)
                break;
            case "Town*":
                setTown(value)
                break;
            case "County*":
                setCounty(value)
                break;
            case "Postcode*":
                setPostcode(value)
                break;
            case "Report purpose*":
                setPurpose(value)
                break;
            case "Bedrooms":
                setBedrooms(value)
                break;
            case "Bathrooms":
                setBathrooms(value)
                break;
            case "Living rooms":
                setLivingRooms(value)
                break;
            case "Kitchens":
                setKitchens(value)
                break;
            case "Sq ft":
                setSize(value)
                break;
            case "If tenanted, how much rent is tenant currently paying per month?":
                setRent(value)
                break;
        }
    }

    const handleOverviewChange = (ev) => {
        if (!ev) return;
        if (!ev.target.value.length) {
            setDetailsCounter(0)
            setExtraDetails(ev.target.value)
            return;
        }
        if (ev.target.value.length > 300) return;
        setDetailsCounter(ev.target.value.length)
        setExtraDetails(ev.target.value)
    }

    const checkPostcode = () => {
        setPostcodeValidity(false)
        let response = property_api.validatePostcode(postcode.replace(" ", ""))
        response.then(response => {
            if (response.data.status !== "success") {
                setPostcodeError(" (Invalid postcode)")
                setProcessing(false);
            }
            setPostcodeValidity(response.data.status === "success");
        }).catch(err => {
            console.log(err);
            setProcessing(false);
        })
    }

    const handleSubmit = () => {
        if (processing) return;
        setSubmitted(true)
        setProcessing(true)
        checkPostcode()
    }

    const submitAddress = () => {
        if (!saon || !street || !town || !county || !postcode || purpose.id === 0 || !postcodeValidity) {
            setProcessing(false)
            return;
        }
        let _bedrooms = bedrooms
        let _bathrooms = bathrooms
        let _livingRooms = livingRooms
        let _kitchens = kitchens
        let _size = size
        let _extraDetails = extraDetails
        let _rent = rent
        if (_bedrooms === undefined || _bedrooms === null || _bedrooms === "") _bedrooms = "-"
        if (_bathrooms === undefined || _bathrooms === null || _bathrooms === "") _bathrooms = "-"
        if (_livingRooms === undefined || _livingRooms === null || _livingRooms === "") _livingRooms = "-"
        if (_kitchens === undefined || _kitchens === null || _kitchens === "") _kitchens = "-"
        if (_size === undefined || _size === null || _size === "") _size = 0
        if (_extraDetails === undefined || _extraDetails === null) _extraDetails = ""
        if (_rent === undefined || _rent === null || _rent === "") _rent = 0
        let body = {
            saon: saon,
            street: street,
            town: town,
            county: county,
            postcode: postcode.toLocaleUpperCase(),
            purpose: purpose,
            additional_info: {
                selected: showAdditionalInfo,
                bedrooms: _bedrooms,
                bathrooms: _bathrooms,
                living_rooms: _livingRooms,
                kitchens: _kitchens,
                size: _size,
                extra_details: _extraDetails,
                rent: _rent
            }
        }
        let _addr_str = saon + " " + street + ", " + town + ", " + county + ", " + postcode.toLocaleUpperCase()
        manualEvaluate(_addr_str, body)
        setProcessing(false)
        closeManualPopup(false)
    }

    useEffect(() => {
        setPostcodeError("")
    }, [postcode]);

    useEffect(() => {
        if (postcodeValidity) {
            submitAddress()
            setPostcodeError("")
        } else {
            if (submitted) {
                setPostcodeError(" (Invalid Postcode)")
            }
        }
    }, [postcodeValidity]);

    const handleClose = () => {
        closeManualPopup(false)
    }

    return(
        <>
            <div className="page-mask dark"/>
            <div className="popup-holding-container">
                <div className="manual-address-popup-container" id="scrollbar2">
                    <div className="manual-address-header-container">
                        <div className="manual-address-header">
                            Add manual address
                        </div>
                        <div className="manual-addreess-header-close link" onClick={handleClose}>
                            <img src={close} alt="X"/>
                        </div>
                    </div>
                    <GenericStrCard title="Flat number, house number or name*" data={saon}
                                    changeValue={changeValue.bind(this)} submitted={submitted}/>
                    <GenericStrCard title="Street name*" data={street}
                                    changeValue={changeValue.bind(this)} submitted={submitted}/>
                    <div className="manual-address-locality-container">
                        <GenericStrCard title="Town*" data={town}
                                        changeValue={changeValue.bind(this)} submitted={submitted}/>
                        <GenericStrCard title="County*" data={county}
                                        changeValue={changeValue.bind(this)} submitted={submitted}/>
                        <div className="generic-string-card-container">
                            <div className="generic-string-card-header-container">
                                <div className={submitted && postcode === "" || postcodeError  ?
                                    "generic-string-card-header invalid" : "generic-string-card-header"}>
                                    Postcode* {postcodeError}
                                </div>
                            </div>
                            <div className="generic-string-text-container">
                                <input type="text" value={postcode} placeholder=""
                                       onChange={(ev) => changeValue("Postcode*", ev.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className="manual-address-purpose-container">
                        <GenericDropdownCard title={"Report purpose*"} data={purpose}
                                             options={purpose_options} changeValue={changeValue.bind(this)}
                                             submitted={submitted}/>
                    </div>
                    <div className="manual-address-additional-info-container">
                        <div className="manual-address-additional-info-checkbox-container link"
                             onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}>
                            {showAdditionalInfo ?
                                <img src={check} alt={"tick"} />
                                :null}
                        </div>
                        <div className="manual-address-additional-info-text">
                            I know additional information about this address
                        </div>
                    </div>
                    {showAdditionalInfo ?
                        <>
                            <div className="manual-address-locality-container">
                                <GenericIntCard title="Bedrooms" data={bedrooms}
                                                changeValue={changeValue.bind(this)} submitted={false}/>
                                <GenericIntCard title="Bathrooms" data={bathrooms}
                                                changeValue={changeValue.bind(this)} submitted={false}/>
                                <GenericIntCard title="Living rooms" data={livingRooms}
                                                changeValue={changeValue.bind(this)} submitted={false}/>
                                <GenericIntCard title="Kitchens" data={kitchens}
                                                changeValue={changeValue.bind(this)} submitted={false}/>
                                <GenericIntCard title="Sq ft" data={size}
                                                changeValue={changeValue.bind(this)} submitted={false}/>
                            </div>
                            <div className="manual-address-comment">
                                <div className="manual-address-comment-title">
                                    Details of any outbuildings or annexes, eg garages, garden shed or office
                                    <div className="manual-address-comment-title-counter">({detailsCounter}/300)</div>
                                </div>
                                <div className="manual-address-comment-text">
                                <textarea type="text" value={extraDetails}
                                          onChange={(ev) => handleOverviewChange(ev)}/>
                                </div>
                            </div>
                            <GenericCurrencyCard title={"If tenanted, how much rent is tenant currently paying per month?"}
                                                 data={rent}
                                                 changeValue={changeValue.bind(this)} />
                        </>
                        :null}
                    <div className="manual-address-submit-button link" onClick={handleSubmit}>
                        {processing ? <ButtonLoadingWheel show={processing && processing}/>
                            : "Get RICS Regulated Valuation Report"}
                    </div>
                    <div className="manual-address-footer">
                        *Indicates mandatory field
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManualAddressPopup