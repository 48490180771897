import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import MainHeader from "../components/MainHeader";
import ValuationAddressSearch from "../components/ValuationAddressSearch";
import MortgageValuation from "../components/MortgageValuation";
import InsightValuation from "../components/InsightValuation";
import AddressLookupFlyout from "../components/AddressLookupFlyout";
import ReportTypesFlyout from "../components/ReportTypesFlyout";
import AddressCurrentValuationHeader from "../components/AddressCurrentValuationHeader";
import val_api from "../api/valuation";
import comparison_api from "../api/comparison";
import GenericDialog from "../components/GenericDialog";
import RICSValuation from "../components/RICSValuation";
import profapi from "../api/profile";
import roles from "../models/Roles";
import fake_property from "../models/FakeProperty";
import pdf_api from "../api/pdf";
import ManualAddressPopup from "../components/ManualAddressPopup";

const Valuation = () => {

    const [profile, setProfile] = useState();
    const [addressString, setAddressString] = useState("")
    const [reportType, setReportType] = useState();
    const [clearAddressToggle, setClearAddressToggle] = useState(false);

    // Report data
    const [valuation, setValuation] = useState();
    const [valuationId, setValuationId] = useState();
    const [summaryId, setSummaryId] = useState();
    const [propertyId, setPropertyId] = useState();
    const [processedDate, setProcessedDate] = useState();
    const [furtherDetails, setFurtherDetails] = useState();
    const [predictedValuation, setPredictedValuation] = useState()

    // Report Status
    const [processedReport, setProcessedReport] = useState(false);
    const [processingReport, setProcessingReport] = useState(false)
    const [generatingValuation, setGeneratingValuation] = useState(false)
    const [displayValuation, setDisplayValuation] = useState(false)
    const [insightDataReady, setInsightDataReady] = useState(false);
    const [enableReport, setEnableReport] = useState(false);

    // Error Handling
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [error, setError] = useState("");
    const okButton = [{"text":"OK", "id": "1"}];

    // Credit issue for retail users
    const [showGetCreditBanner, setShowGetCreditBanner] = useState(false);
    const [checkAccuracy, setCheckAccuracy] = useState();

    const [params] = useSearchParams();
    const [insightParams, setInsightParams] = useState(false)

    const [showNoData, setShowNoData] = useState(false)

    const [showManualPopup, setShowManualPopup] = useState(false)

    useEffect(() => {
        getProfile()
    }, [])

    const getProfile = () => {
        let response = profapi.getProfile();
        response.then(
            response => {
                setProfile(response.data);
            }
        ).catch(err => {
            console.log(err);
            setError("Failed to retrieve profile data");
            setShowErrorDialog(true);
        })
    }



    const updateAccuracy = (property_id) => {
        let response = val_api.valuationAccuracyCheck(property_id);
        response.then(
            response => {
                setCheckAccuracy(response.data.score);
            }
        ).catch(err => {
            console.log(err);
            setError("Unable to check valuation accuracy value");
            setShowErrorDialog(true);
        })
    }

    useEffect(() => {
        if (!profile) return
        let localStorageValuation= sessionStorage.getItem("in_progress_valuation")
        if (!localStorageValuation) return;
        let localStorageValuation_json = JSON.parse(localStorageValuation)
        // Get values from local storage
        let _property_id = localStorageValuation_json.addr
        let _addr_str = localStorageValuation_json.addr_str
        let _typ = localStorageValuation_json.typ

        // Execute valuation
        evaluate(_property_id, _addr_str, _typ)
        sessionStorage.removeItem("in_progress_valuation")
    }, [profile])

    useEffect(() => {
        if (!profile) return;
        if (params.get('id') !== null) {
            let summary_id = params.get('id')
            RecreateInsight(summary_id)
        }
    }, [profile]);
    const RecreateInsight = (summary_id) => {
        // Setup
        setReportType(3);
        setProcessedReport(false);
        setDisplayValuation(false)
        setClearAddressToggle(false);
        setGeneratingValuation(true)

        let response = comparison_api.getOverview(summary_id);
        response.then(response => {
                setValuation(response.data);
                setPropertyId(response.data.property_id)
                let address = response.data.address
                setAddressString((address.paon).replace(" ", ",") + " " +address.saon + " " + address.street + ", " + address.postcode)
                setSummaryId(summary_id)
                setPredictedValuation(response.data.preditcted_valuation)
                setProcessedDate(response.data.processed_date);
                setFurtherDetails(response.data.further_details);
                setDisplayValuation(true)
                setInsightParams(true)
                clearAddress();
                setGeneratingValuation(false)
            }
        ).catch(error => {
            setError("Failed to retrieve insight");
            setShowErrorDialog(true);
            setGeneratingValuation(false)
        })
    }

    const manualEvaluate = (addr_str, body) => {
        if (!profile || !body) return;
        // Setup
        setProcessedReport(false);
        setDisplayValuation(false)
        setClearAddressToggle(false);
        setGeneratingValuation(true)
        setProcessingReport(true)
        setReportType(2)
        setAddressString(addr_str)
        let response = val_api.postManualValuation(body)
        response.then(response => {
                setValuation(response.data.valuation);
                setValuationId(response.data.report_guid);
                setSummaryId(response.data.valuation_summary_id)
                setPredictedValuation(response.data.preditcted_valuation)
                setProcessedDate(response.data.processed_date);
                setFurtherDetails(response.data.valuation.further_details);
                setDisplayValuation(true)
                setInsightParams(false)
                clearAddress();
                setGeneratingValuation(false)
            }
        ).catch(error => {
                if (error.response.data && error.response.data.description){
                    if (profile.role_id === roles.ROLE_RETAIL_USER &&
                        error.response.data.description.includes("Insufficient credits")){
                        // Add values for current valuation into session storage if insufficient credits
                        sessionStorage.setItem("in_progress_valuation",
                            JSON.stringify({"addr": 0, "addr_str": addr_str, "typ": 2}));
                        // Render the page with no data
                        let data = fake_property.valuation_data(0)
                        setValuation(data.valuation);
                        setValuationId(data.valuation.id);
                        setPredictedValuation(data.valuation.predicted_value);
                        setProcessedDate(data.processed_date);
                        setFurtherDetails(data.valuation.further_details);
                        setDisplayValuation(true);
                        clearAddress();
                        setGeneratingValuation(false);
                        setShowGetCreditBanner(true);
                        setProcessingReport(false)
                    }
                    else {
                        setError(error.response.data.description);
                        setShowErrorDialog(true);
                        setGeneratingValuation(false)
                        setProcessingReport(false)
                        clearAddress();
                    }
                }
                else {
                    console.log(error);
                    setError("Failed to retrieve valuation");
                    setShowErrorDialog(true);
                    setGeneratingValuation(false)
                    setProcessingReport(false)
                }
            }
        )
    }

    const evaluate = (propertyID, addr_str, typ, noData, purpose=null, details =null) => {
        if (!profile) return;

        // Set passed values from address bar
        setAddressString(addr_str)
        setReportType(typ);
        if (noData) {
            setShowNoData(true)
            return;
        }
        setShowNoData(false)
        setPropertyId(propertyID)

        // Setup
        setProcessedReport(false);
        setDisplayValuation(false)
        setClearAddressToggle(false);
        setGeneratingValuation(true)
        setProcessingReport(true)
        let _body = {
            property_id: propertyID,
            type: typ,
            purpose: purpose,
            details: details
        }

        // Get Report
        let response = val_api.postValuation(_body);
        response.then(response => {
                setValuation(response.data.valuation);
                setValuationId(response.data.report_guid);
                setSummaryId(response.data.valuation_summary_id)
                setPredictedValuation(response.data.preditcted_valuation)
                setProcessedDate(response.data.processed_date);
                setFurtherDetails(response.data.valuation.further_details);
                setDisplayValuation(true)
                setInsightParams(false)
                clearAddress();
                setGeneratingValuation(false)
            }
        ).catch(error => {
                if (error.response.data && error.response.data.description){
                    if (profile.role_id === roles.ROLE_RETAIL_USER &&
                        error.response.data.description.includes("Insufficient credits")){
                        // Add values for current valuation into session storage if insufficient credits
                        sessionStorage.setItem("in_progress_valuation",
                            JSON.stringify({"addr": propertyID, "addr_str": addr_str, "typ": typ}));
                        // Render the page with no data
                        let data = fake_property.valuation_data(propertyID)
                        setValuation(data.valuation);
                        setValuationId(data.valuation.id);
                        setPredictedValuation(data.valuation.predicted_value);
                        setProcessedDate(data.processed_date);
                        setFurtherDetails(data.valuation.further_details);
                        setDisplayValuation(true);
                        clearAddress();
                        setGeneratingValuation(false);
                        updateAccuracy(propertyID);
                        setShowGetCreditBanner(true);
                        setProcessingReport(false)
                    }
                    else {
                        setError(error.response.data.description);
                        setShowErrorDialog(true);
                        setGeneratingValuation(false)
                        setProcessingReport(false)
                        clearAddress();
                    }
                }
                else {
                    console.log(error);
                    setError("Failed to retrieve valuation");
                    setShowErrorDialog(true);
                    setGeneratingValuation(false)
                    setProcessingReport(false)
                }
            }
        )

    }

    const handleOKDialogResult = (res) => {
        setShowErrorDialog(false);
    }

    const closeReport = () => {
        setValuation(null);
        setProcessedReport(false);
        setDisplayValuation(false);
        setInsightDataReady(false);
        setEnableReport(false);
        setShowNoData(false)
        clearAddress();
    }

    const clearAddress = () => {
        // Prepare state for next request
        setClearAddressToggle(true);
        setInsightDataReady(false);
        setEnableReport(false);
    }

    const updateInsightDataReady = () => {
        setInsightDataReady(true);
        let body = {
            "property_id": propertyId,
            "summary_id": summaryId,
            "report_type": 3
        }
        let response = pdf_api.processReport(body);
        response.then(
            response => {
                setEnableReport(true);
                setProcessingReport(false);
            }
        ).catch(err => {
            console.log(err);
            setError("Processing report failure");
            setShowErrorDialog(true);
            setProcessingReport(false);
            setEnableReport(false);
        })
    }
    const handleManualPopup = (boolean) => {
        setShowManualPopup(boolean)
    }

    return (
        <>
            <MainHeader />
            {showManualPopup ?
                <ManualAddressPopup manualEvaluate={manualEvaluate.bind(this)}
                                    closeManualPopup={handleManualPopup.bind(this)}/>
                :null}
            <div className="valuation-page-container">
                <div className="valuation-container">
                    <ValuationAddressSearch evaluate={evaluate.bind(this)} clear={clearAddressToggle}
                                            resetSearch={clearAddress.bind(this)} profile={profile}
                                            manualEvaluate={manualEvaluate.bind(this)} handleManualPopup={handleManualPopup.bind(this)}/>
                    <div className="valuation-content-container">
                        {displayValuation || generatingValuation || showNoData
                            ? <AddressCurrentValuationHeader addressString={addressString}
                                                             close={closeReport.bind(this)}
                                                             reportType={reportType}
                                                             processingReport={processingReport}
                                                             enableReport={enableReport}
                                                             summaryId={summaryId}/>
                            : null }
                        {generatingValuation && generatingValuation ?
                            <div className="loading-valuation-container">
                                <div className="valuation-result-container">
                                    <div className="loading-valuation-text">
                                        We are preparing your report.
                                    </div>
                                    <div className="valuation-loading-bar-container">
                                        <div className="valuation-loading-bar-bouncer" />
                                    </div>
                                </div>
                            </div>
                            : null}
                        {displayValuation && displayValuation
                            ?
                            <>
                            {reportType === 1
                                ? <MortgageValuation valuation={valuation}
                                                     valuationId={summaryId}
                                                     processedDate={processedDate}
                                                     furtherDetails={furtherDetails}
                                                     setError={setError.bind(this)}
                                                     setShowErrorDialog={setShowErrorDialog.bind(this)}/>
                                : (reportType === 2)
                                    ? <RICSValuation valuation={valuation}
                                                     valuationId={summaryId}
                                                     processedDate={processedDate}
                                                     furtherDetails={furtherDetails}
                                                     setError={setError.bind(this)}
                                                     setShowErrorDialog={setShowErrorDialog.bind(this)}/>
                                    : (reportType === 3)
                                        ? <InsightValuation valuation={valuation}
                                                            valuationId={summaryId}
                                                            addressString={addressString}
                                                            propertyId={propertyId}
                                                            summaryId={summaryId}
                                                            predicted_valuation={predictedValuation}
                                                            processedDate={processedDate}
                                                            furtherDetails={furtherDetails}
                                                            params={insightParams}
                                                            setError={setError.bind(this)}
                                                            setShowErrorDialog={setShowErrorDialog.bind(this)}
                                                            showCreditBanner={showGetCreditBanner}
                                                            check_accuracy={checkAccuracy}
                                                            dataReady={insightDataReady}
                                                            updateInsightDataReady={updateInsightDataReady.bind(this)}/>
                                        : null}
                                </>
                                : null}
                        {showNoData && showNoData ?
                            <div className="valuation-no-data-container">
                                <div className="valuation-result-container">
                                    <div className="no-data-description">
                                        We’re sorry, we don’t have enough data to make an accurate valuation for this
                                        address. We’re constantly updating our datasets so we hope to cover this address
                                        soon.
                                    </div>
                                    <div className="no-data-description">
                                        Thank you for your patience.
                                        <br/>
                                        You haven’t been charged for this valuation.
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </div>

            {showErrorDialog
                ? <GenericDialog title="Valuation - Error" text={error} buttons={okButton}
                                 result={handleOKDialogResult.bind(this)} />
                : null
            }
        </>
    )
}

export default Valuation