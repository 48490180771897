import axiosInstance from "./http/axios_instance"
const http = axiosInstance("/protected",  null, "/forbidden")

const postValuation = (body) => http.post(`/valuation`, body);

const getMyValuation = (summary_id) => http.get("/valuation/review/" + summary_id)

const getMyValuations = (search_data) => http.post("/user_valuation_requests", search_data);

const getValuationHistory = (summary_id) => http.get("/valuation_history/" + summary_id);

const postValuationQuery = (body) => http.post("/valuation_history",body);

const postFileValuationQuery = (body) => http.post("/file_valuation_history", body);

const getValuationFile = (summary_id, filename) => http.get("/file_valuation_history/" + summary_id + "/" + filename);

const deleteNotifications = (summary_id) => http.post("/valuation_notifications/" + summary_id);

const setUnderReview = (summary_id) => http.put("/valuation/under_review/" + summary_id);
const setReopen = (summary_id) => http.put("/valuation/reopen/" + summary_id);

const setApproved = (summary_id, data) => http.put("/valuation/approve/" + summary_id, data);

const setRejected = (summary_id) => http.put("/valuation/reject/" + summary_id);

const setAmended = (summary_id, data) => http.put("/valuation/amend/" + summary_id, data);
const valuationAccuracyCheck = (property_id) => http.get("/valuation/check/" + property_id);
const getValuationTypes = () => http.get("/valuation_types")
const getValuationPurpose = () => http.get("/valuation_purpose")

const postManualValuation = (body) => http.post("/manual_valuation", body)

export default {
    postValuation,
    getMyValuations,
    getMyValuation,
    getValuationHistory,
    postValuationQuery,
    deleteNotifications,
    setUnderReview,
    setReopen,
    setApproved,
    setRejected,
    setAmended,
    valuationAccuracyCheck,
    getValuationTypes,
    getValuationPurpose,
    postFileValuationQuery,
    getValuationFile,
    postManualValuation
}