import dateUtils from "../../utils/DateUtils";
import papi from "../../api/pdf";
import address_formatter from "../../utils/Address";
import GenericDialog from "../GenericDialog";
import {useEffect, useState} from "react";
import val_api from "../../api/valuation";
import valuer_api from "../../api/valuer";
import {useNavigate} from "react-router-dom";

const ValuerReviewInfo = (props) => {
    const report = props.report;
    const updateReport = props.updateReport;
    const clickLog = props.clickChangeLog;
    const reportGeneration = props.reportGeneration
    const updateGeneration = props.updateGeneration
    const navigate = useNavigate();
    const [showDownloading, setShowDownloading] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [error, setError] = useState();
    const okButton = [{"text":"OK", "id": "1"}];

    const [valuers, setValuers] = useState();
    const [states, setStates] = useState();

    const [newValuer, setNewValuer] = useState();
    const [newStatus, setNewStatus] = useState();

    const [requestType, setRequestType] = useState("Database address")

    const getValuers = () => {
        let response = valuer_api.getValuers();
        response.then(
            response => {
                setValuers(response.data.valuers);
            }
        ).catch(err => {
            console.log(err);
        })
    }

    const getStates = () => {
        let response = valuer_api.getManualValuerStatuses()
        response.then(
            response => {
                let list = [{"id":6,"name":"New"}];
                list.push(...response.data.statuses);

                // allow a valuer to set the valuer's status if comments have triggered it to be reopened
                if(report.summary_status_id === 4){
                    let status = {
                        "id": 9,
                        "name": "Resolved"
                    }
                    list.push(status)
                }
                // allow a valuer to set the valuer's status if comments have triggered it to be reopened
                if(report.summary_status_id === 5){
                    let status = {
                        "id": 7,
                        "name": "Closed"
                    }
                    list.push(status)
                }

                setStates(list);
            }
        ).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        if (!report) return;
        getValuers()
        getStates()
        setNewValuer(report.valuer_id)
        setNewStatus(report.current_status_id)
        if (report.manual_addition) {
            setRequestType("Manual address")
        }
    }, [report])

    function handleReportClick(ev) {
        setShowDownloading(true);
        let response = papi.getReport(ev.target.id);
        response.then(
            response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let filename = address_formatter.getPdfFilename(report);
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        ).catch((err) => {
            setError("Failed to download report");
            setShowErrorDialog(true);
        }).finally(() => {
                setShowDownloading(false);
            }
        )
    }

    function handleCsvClick(ev) {
        setShowDownloading(true);
        let response = valuer_api.getValuerCsv(ev.target.id);
        response.then(
            response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                // let filename = address_formatter.getPdfFilename(report);
                link.setAttribute('download', address_formatter.getFileName(report, '.csv'));
                document.body.appendChild(link);
                link.click();
            }
        ).catch((err) => {
            setError("Failed to download report");
            setShowErrorDialog(true);
        }).finally(() => {
                setShowDownloading(false);
            }
        )
    }

    const handleOKDialogResult = (res) => {
        setShowErrorDialog(false);
    }

    function handleStatusChange() {
        if (!report) return;
        if (newValuer !== report.valuer_id){
            let response = valuer_api.changeValuer(report.id, newValuer);
            response.then(
                () => {
                    navigate("/valuer");
                }
            ).catch(err => {
                setError("Failed to update the valuer");
                setShowErrorDialog(true);
            })
        }
        else if (parseInt(newStatus) !== report.current_status_id){
            let response = valuer_api.changeStatus(report.id, parseInt(newStatus));
            response.then(
                () => {
                    navigate("/valuer");
                }
            ).catch(err => {
                setError("Failed to update the status");
                setShowErrorDialog(true);
            })
        }
    }

    function handleLogClick() {
        clickLog();
    }

    return (
        <>
            <div className="valuer-review-info-container">
                <div className="valuer-review-info-value-section">
                    <div className="valuer-review-row">
                        <div className="valuer-review-cell">Request type</div>
                        <div  className="valuer-review-cell">{requestType}</div>
                    </div>
                    <div className="valuer-review-row">
                        <div className="valuer-review-cell bold">Value</div>
                        <div  className="valuer-review-cell bold">{report && "£" + report.valuation.toLocaleString()}</div>
                    </div>
                </div>
                <div className="valuer-review-info-document-section">
                    <div className="valuer-review-document-header">
                        <div className="valuer-review-document-head">Document</div>
                        <div className="valuer-review-document-head">Last Updated</div>
                    </div>
                    <div className="valuer-review-document-rows">
                        <div className="valuer-review-document-row">
                            <div className="action col" onClick={handleReportClick} id={report && report.summary_id}>
                                {report && report.rr_iteration !== "A" ?
                                    <>View Report</>
                                    :null}
                            </div>
                            <div className="date col">{report && dateUtils.display_date_time(report.last_updated_date*1000)}</div>
                        </div>
                    </div>
                    <div className="valuer-review-document-rows">
                        <div className="valuer-review-document-row">
                            <div className="action col" onClick={handleCsvClick} id={report && report.summary_id}>Download CSV</div>
                        </div>
                    </div>
                </div>
                <div className="valuer-review-info-requester-section">
                    <div className="valuer-review-requester-header">
                        <div className="valuer-review-requester-head">Requested by</div>
                        <div className="valuer-review-requester-head">Client</div>
                    </div>
                    <div className="valuer-review-requester-row">
                        <div className="label">{report && (report.request.firstname + " " + report.request.lastname)}</div>
                        <div className="content">{report && report.request.orgname}</div>
                    </div>
                    <div className="valuer-review-requester-row">
                        <div className="label">{report && report.request.email}</div>
                    </div>
                </div>
                <div className="valuer-review-info-status-section">
                    <div className="valuer-review-status-row">
                        <div className="label">Assigned to</div>
                        <div className="content">
                            <select value={newValuer}
                                    onChange={(ev) => setNewValuer(ev.target.value)}
                                    disabled={report && (report.current_status_id === 7 ||
                                        report.current_status_id === 9)}>
                                {valuers && valuers.map((valuer, idx) => {
                                    return (
                                        <option value={valuer.id}>{valuer.firstname} {valuer.lastname}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="valuer-review-status-row">
                        <div className="label">Ticket status to</div>
                        <div className="content">
                            <select value={newStatus}
                                    onChange={(ev) => setNewStatus(ev.target.value)}
                                    disabled={report && (report.current_status_id === 7 ||
                                        report.current_status_id === 9)}>
                                {states && states.map((state, idx) => {
                                    return (
                                        <option value={state.id} disabled={state.id === 6}>{state.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="valuer-review-status-row">
                        <div className="valuer-review-changelog-link" onClick={handleLogClick}>
                            See full change log
                        </div>
                        <div className="content right">
                            <button onClick={handleStatusChange}
                                    disabled={report && (report.current_status_id === 7 ||
                                        report.current_status_id === 9)}>Save</button>
                        </div>
                    </div>
                    <div className="valuer-review-rics-container">
                        <div className="billing-check-label">Include FINVISE RICS report</div>
                        <label className="billing-check-container">
                            <input type="checkbox" checked={reportGeneration} onChange={updateGeneration}/>
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
            {showDownloading ? <GenericDialog title="Download" text="Your report is being prepared"/>
                : null}
            {showErrorDialog ?
                <GenericDialog title="Review - Error" text={error} buttons={okButton}
                               result={handleOKDialogResult.bind(this)} /> : null }
        </>
    )
}

export default ValuerReviewInfo