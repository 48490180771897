import {useEffect} from "react";


const SearchDetailRow = (props) => {
    const value = props.value
    const detail = props.detail
    const increment = props.increment
    const reduction = props.reduction

    const checkMaxValue = (value, name) => {
        let _classname = "counter-button-container"
        if (!value || !name) return _classname;
        if (name === "Bedrooms" || name === "Bathrooms") {
            if (value === "5+") {
                _classname = "counter-button-container disabled"
            }
        }
        return _classname;
    }

    const getRowClassName = (name) => {
        let _classname = "valuation-type-option-container counter middle"
        if (name) return _classname;
        if (name === "Bedrooms") {
            _classname = "valuation-type-option-container counter first"
        }
        return _classname
    }

    return(
        <div className={getRowClassName(detail.name)} id={detail.id}
             key={detail.id}>
            <div className="valuation-type-option counter">
                <div className="valuation-type-option-title">
                    {detail.name}
                </div>
                <div className="valuation-type-option-input">
                    <div className={value === "-" ? "counter-button-container disabled": "counter-button-container"} onClick={() => reduction(detail.id)}>-</div>
                    <div className="counter-value-container">{value}</div>
                    <div className={checkMaxValue(value, detail.name)} onClick={() => increment(detail.id)}>+</div>
                </div>
            </div>
        </div>
    )
}

export default SearchDetailRow