import val_api from "../../api/valuation";
import valuer_api from "../../api/valuer"
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import address_formatter from "../../utils/Address";
import cloud from "../../assets/upload_cloud.png";
import cancel from "../../assets/cancel_white.png";
import ImageUtils from "../../utils/ImageUtils";
import GenericDialog from "../GenericDialog";
import ButtonLoadingWheel from "../ButtonLoadingWheel";

const ValuerReviewApprove = (props) => {
    const report = props.report;
    const reportGeneration = props.reportGeneration
    const navigate = useNavigate();

    const [files, setFiles] = useState([])
    const [comment, setComment] = useState();
    const [asterisk, setAsterisk] = useState(false)

    const [processing, setProcessing] = useState(false)

    // Error Handling
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [error, setError] = useState("");
    const okButton = [{"text":"OK", "id": "1"}];

    const handleOKDialogResult = (res) => {
        setShowErrorDialog(false);
    }

    function handleApproveClick() {
        if (processing) return;
        setProcessing(true)
        let data = {
            'comment': comment,
            'asterisk': asterisk,
            'reportGeneration': reportGeneration,
            'files': files
        }
        let response = val_api.setApproved(report.summary_id, data);
        response.then(
            response => {
                setProcessing(false)
                navigate("/valuer");
            }
        ).catch(
            err => {
                setProcessing(false)
                console.log(err);
                setError(err.response.data.description);
                setShowErrorDialog(true);
            }
        )
    }

    const handlePreview = () => {
        if (!report) return;
        let response = valuer_api.getReportPreview(report.summary_id)
        response.then(
            response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let filename = address_formatter.getPdfFilename(report);
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }

    const handleAsterisk = () => {
        setAsterisk(!asterisk)
    }

    const handleDragOver = (ev) => {
        ev.preventDefault()
    }

    const handleDrop = async (ev) => {
        ev.preventDefault()
        let _files = ev.dataTransfer.files
        let _temp_files = []
        if (_files[0].type === "application/pdf" && _files[0].size < 10000000) {
            let base64_image = await ImageUtils.convertBase64(_files[0]);
            let _file = {name: _files[0].name, base64: base64_image, caption: ""}
            _temp_files.push(_file)
        }
        setFiles(_temp_files)
    }

    const handleRemoveFile = (ev) => {
        if (!ev) return;
        let _files = [...files]
        _files.splice(ev.target.id, 1)
        setFiles(_files)
    }

    useEffect(() => {
        if (!report) return;
        setAsterisk(report.asterisk)
    },[report])

    return (
        <>
            <div className="valuer-approve-container">
                <div className="valuer-approve-header-container">
                    <div className="valuer-approve-text-container">
                        <div className="valuer-approve-address-container">
                            <div className="valuer-approve-address-line">
                                {report && report.address}
                            </div>
                            <div className="valuer-approve-address-line">
                                {report && report.postcode}
                            </div>
                        </div>
                        <div className="valuer-approve-valuation">
                            {report && "£" + report.valuation.toLocaleString()}
                        </div>
                    </div>
                    <div className="valuer-asterisk-section-container">
                        <div className="valuer-asterisk-container link" onClick={handleAsterisk}>
                            {asterisk ?
                                <div className="valuer-asteric-point"/>
                                :null}
                        </div>
                        <div className="valuer-asterisk-text">Add asterisk to market value</div>
                    </div>
                </div>
                <div className="valuer-amend-comment">
                    <div className="valuer-amend-label">Official remarks for the RICS report</div>
                    <div className="valuer-amend-comment-text">
                        <textarea type="text" value={comment}
                                  onChange={(ev) => setComment(ev.target.value)}/>
                    </div>
                </div>
                <div className="valuer-preview-button link" onClick={handlePreview}>Preview Report</div>
                {reportGeneration ?
                    null
                    :
                    <>
                        <div className="valuer-appendix-divider"/>
                        <div className="valuer-appendix-title">Additional document upload </div>
                        <div className="valuer-dropzone" onDragOver={handleDragOver} onDrop={handleDrop}>
                            <img src={cloud} alt="upload cloud"/>
                            <div>Drag and Drop PDF to Upload</div>
                        </div>
                    </>
                }
                {files && files.map((file, idx) => {
                    return(
                        <>
                            <div className={reportGeneration ? "valuer-appendix-file-row" : "valuer-appendix-file-row static"}>
                                <div className="valuer-appendix-file-name">
                                    {file.name}
                                </div>
                                <div id={idx.toString()} className="valuer-appendix-file-delete link" onClick={(ev) => handleRemoveFile(ev)}>
                                    <img id={idx.toString()} src={cancel} alt="delete"/>
                                </div>
                            </div>
                        </>

                    )
                })}
                <div className="valuer-appendix-divider"/>
                <div className="valuer-approve-button-container">
                    <div className="valuer-button-intro">
                        Do you approve the report?
                    </div>
                    <div className={processing ? "valuer-approve-button" : "valuer-approve-button link"} onClick={handleApproveClick}>
                        {processing ?
                            <div className="valuer-submit-container">
                                <ButtonLoadingWheel show={processing}/>
                            </div>
                            :
                            <>Approve valuation report</>
                        }
                    </div>
                </div>
                {showErrorDialog
                    ? <GenericDialog title="Report generation error" text={error} buttons={okButton}
                                     result={handleOKDialogResult.bind(this)} />
                    : null
                }
            </div>
        </>
    )
}

export default ValuerReviewApprove