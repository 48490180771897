import {useEffect, useState} from "react";


const GenericStrCard = (props) => {
    const title = props.title
    const data = props.data
    const changeValue = props.changeValue
    // Always pass false for submitted if input isn't mandatory
    const submitted = props.submitted

    const [newValue, setNewValue] = useState()
    const [trimmedValue, setTrimmedValue] = useState()

    const handleChange = (ev) => {
        let _value = ev.target.value
        setNewValue(_value)
        changeValue(title, _value)
    }

    useEffect(() => {
        setNewValue(data)
        setTrimmedValue(data.trim())
    },[data])

    return(
        <>
            <div className="generic-string-card-container">
                <div className="generic-string-card-header-container">
                    <div className={submitted && newValue === "" ?
                        "generic-string-card-header invalid" : "generic-string-card-header"}>
                        {title}
                    </div>
                </div>
                <div className="generic-string-text-container">
                    <input type="text" value={newValue} placeholder="" onChange={handleChange}/>
                </div>
            </div>
        </>
    )
}

export default GenericStrCard