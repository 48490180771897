import {useEffect, useState} from "react";
import InsightKeyDetail from "./InsightKeyDetail";
import PropertyCards from "./PropertyCards";
import InsightLocalArea from "./InsightLocalArea";
import recent_sales_api from "../api/InsightRecentSales";
import recent_rent_api from "../api/InsightRencentRent"
import property_api from "../api/property"
import ValuationInfoPopup from "./ValuationInfoPopup";
import InsightRangePopup from "./InsightRangePopup";
import InsightRecentActivity from "./InsightRecentActivity";
import InsightHistoryLineChart from "./InsightHistoryLineChart";
import {Link, useNavigate} from "react-router-dom";
import GoogleMapsWidget from "./GoogleMapsWidget";
import GoogleStreetWidget from "./GoogleStreetWidget";
import info_img from "../assets/info.png";
import GetCreditBanner from "./GetCreditBanner";
import CompareProperties from "./CompareProperties";
import GenericDialog from "./GenericDialog";

const InsightValuation = (props) => {
    const navigate = useNavigate();

    // Report Data
    const [valuation, setValuation] = useState();
    const [postcode, setPostcode] = useState();
    const [processedDate, setProcessedDate] = useState();
    const [furtherDetails, setFurtherDetails] = useState();
    const params = props.params;
    const addressString = props.addressString;
    const predictedValue = props.valuation.predicted_valuation;
    const accuracy = props.valuation.accuracy;
    const summaryId = props.summaryId;
    const propertyId = props.propertyId;
    const showCreditBanner = props.showCreditBanner;
    const checkAccuracy = props.check_accuracy;
    const dataReady = props.dataReady;
    const updateDataReady = props.updateInsightDataReady;

    // Key detail card type
    const single = true;

    // Range component data
    const [minRange, setMinRange] = useState(0);
    const [maxRange, setMaxRange] = useState(0);

    // tab menus
    const [rentTabMenus, setRentTabMenus] = useState();
    const [saleTabMenus, setSaleTabMenus] = useState();
    const [localTabMenus, setLocalTabMenus] = useState();

    // Property coords
    const [propertyCoords, setPropertyCoords] = useState();

    // Key details cards
    const [details, setDetails] = useState();
    const [extraDetails, setExtraDetails] = useState();

    // Recent data
    const [bedrooms, setBedrooms] = useState();
    const [salesAvgData, setSalesAvgData] = useState([]);
    const [salesAvgComplete, setSalesAvgComplete] = useState(false);
    const [rentAvgComplete, setRentAvgComplete] = useState(false);
    const [salesData, setSalesData] = useState([]);
    const [rentData, setRentData] = useState([]);

    // Local area
    const [localDataReady, setLocalDataReady] = useState(false);

    // Compare properties
    const [showCompare, setShowCompare] = useState(false);

    // Errors
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [error, setError] = useState();
    const okButton = [{"text":"OK", "id": "1"}];

    const [mapLocation, setMapLocation] = useState();

    const [historicData, setHistoricData] = useState([])

    const setPercentageStyle = () => {
        let elements = document.getElementsByClassName("actual-degree");
        if (!elements) return;
        const degrees = (valuation.accuracy / 100)  * 135;
        for (let i=0;i<elements.length;i++){
            elements[i].style.setProperty('--final-degree', degrees + 'deg');
        }
    }

    useEffect(() => {
        if (!predictedValue || !accuracy) return;
        if (accuracy > 85) {
            // Minimum range of +- 7.5%
            setMinRange(predictedValue - (predictedValue * ((100 - 85) / 200)))
            setMaxRange(predictedValue + (predictedValue * ((100 - 85) / 200)))
        } else {
            setMinRange(predictedValue - (predictedValue * ((100 - accuracy) / 200)))
            setMaxRange(predictedValue + (predictedValue * ((100 - accuracy) / 200)))
        }
    },[predictedValue, accuracy])

    const handleComparison = () => {
        setShowCompare(!showCompare)
    }

    const handleOKDialogResult = (res) => {
        setShowErrorDialog(false);
    }

    useEffect(() => {
        if (!valuation) return;
        // Setup historic data
        let _a = []
        if (params === true) {
            let data = valuation.recent_property_data;
            for (let i = 0; i < data.length; i++) {
                if (data[i].type === 1) {
                    _a.push(data[i].data)
                }
            }
            setHistoricData(_a)
        } else {
            let data = valuation.historic_values
            for (let i =0; i< data.length; i++) {
                _a.push(data[i])
            }
            setHistoricData(_a)
        }
        setPercentageStyle();
        setMapLocation(addressString.replace(" ", ","))
        let _coordinates = {
            "longitude": valuation.address.longitude,
            "latitude": valuation.address.latitude
        }
        setPropertyCoords(_coordinates)
    }, [valuation])

    useEffect(() => {
        let menus = [];

        let salesMenu = {};

        salesMenu = {};
        salesMenu.name= "Recently sold";
        salesMenu.activeClass = "link noselect submenu-item";
        salesMenu.width = 60;
        menus.push(salesMenu);

        salesMenu = {};
        salesMenu.name= "Average prices by area";
        salesMenu.activeClass = "link noselect submenu-item";
        salesMenu.width = 35;
        menus.push(salesMenu);

        setSaleTabMenus(menus);
    }, []);

    useEffect(() => {
        let menus = [];

        let rentMenu = {};

        rentMenu = {};
        rentMenu.name= "Average rental income";
        rentMenu.activeClass = "link noselect submenu-item";
        rentMenu.width = 35;
        menus.push(rentMenu);

        setRentTabMenus(menus);
    }, []);

    useEffect(() => {
        let menus = [];

        let localMenu = {};
        localMenu.name = "Environment";
        localMenu.activeClass = "active local link noselect submenu-item";
        localMenu.width = 128;
        menus.push(localMenu);

        localMenu = {};
        localMenu.name = "Food and drink";
        localMenu.activeClass = "local link noselect submenu-item";
        localMenu.width = 145;
        menus.push(localMenu);

        localMenu = {};
        localMenu.name = "Schools";
        localMenu.activeClass = "local link noselect submenu-item";
        localMenu.width = 82;
        menus.push(localMenu);

        localMenu = {};
        localMenu.name = "Medical";
        localMenu.activeClass = "local link noselect submenu-item";
        localMenu.width = 90;
        menus.push(localMenu);

        localMenu = {};
        localMenu.name = "Transport";
        localMenu.activeClass = "local link noselect submenu-item";
        localMenu.width = 104;
        menus.push(localMenu);

        setLocalTabMenus(menus);
    }, []);

    useEffect(() => {
        setValuation(props.valuation)
        setPostcode(props.valuation.address.postcode)
        setProcessedDate(props.processedDate)
        setFurtherDetails(props.furtherDetails)
        updateFurtherDetails()
    }, [props.processedReport, props.valuation, props.valuationId, props.processedDate, props.furtherDetails])

    useEffect(() => {
        if (!postcode || !bedrooms || !summaryId) return;
        GetRecentRentData(false)
        GetRecentSalesAvgData(false)
        GetRecentSalesData()
    },[summaryId, bedrooms, postcode])

    const GetRecentSalesAvgData = async (rerun) => {
        if (!postcode || !bedrooms || !summaryId) return;
        let _s = []
        if (params === true) {
            let _data = valuation.recent_property_data;
            for (let i = 0; i < _data.length; i++) {
                if (_data[i].type === 2) {
                    for (let j = 0; j < _data[i].data.length; j++) {
                        if (_data[i].data[j].similar_properties_avg !== 0 && _s.length < 4) {
                            _s.push(_data[i].data[j])
                        }
                    }
                }
            }
            setSalesAvgData(_s)
            setSalesAvgComplete(true)
        } else {
            let response = recent_sales_api.getRecentSalesAvg(postcode, summaryId,  bedrooms, rerun)
            response.then(response => {
                let _data = response.data
                let _rerun = _data.shift()
                if (_rerun.complete === 'false') {
                    for (let i = 0;i < _data.length;i++) {
                        if (_data[i].similar_properties_avg !== 0) {
                            _s.push(_data[i])
                        }
                    }
                    setSalesAvgData(_s)
                    setTimeout(GetRecentSalesAvgData(true), 20000)
                } else {
                    for (let i = 0;i < _data.length;i++) {
                        if (_data[i].similar_properties_avg !== 0) {
                            _s.push(_data[i])
                        }
                    }
                    setSalesAvgData(_s)
                    setSalesAvgComplete(true)
                }
            }).catch(error => {
                setSalesAvgComplete(true)
            })
        }

    }

    const GetRecentSalesData = () => {
        if(!valuation) return;
        setSalesData(valuation.related_properties);
    }

    const GetRecentRentData = async (rerun) => {
        if (!postcode || !bedrooms || !summaryId) return;
        let _s = []
        if (params === true) {
            let _data = valuation.recent_property_data;
            for (let i = 0; i < _data.length; i++) {
                if (_data[i].type === 3) {
                    for (let j = 0; j < _data[i].data.length; j++) {
                        if (_data[i].data[j].similar_properties_avg !== 0 && _s.length < 4) {
                            _s.push(_data[i].data[j])
                        }
                    }
                }
            }
            setRentData(_s)
            setRentAvgComplete(true)
        } else {
            let response = recent_rent_api.getRecentRent(postcode, summaryId, bedrooms, rerun)
            response.then(response => {
                let _data = response.data
                let _rerun = _data.shift()
                if (_rerun.complete === 'false') {
                    for (let i = 0;i < _data.length;i++) {
                        if (_data[i].similar_properties_avg !== 0) {
                            _s.push(_data[i])
                        }
                    }
                    setRentData(_s)
                    setTimeout(GetRecentRentData(true), 10000)
                } else {
                    for (let i = 0;i < _data.length;i++) {
                        if (_data[i].similar_properties_avg !== 0) {
                            _s.push(_data[i])
                        }
                    }
                    setRentData(_s)
                    setRentAvgComplete(true)
                }
            }).catch(error => {
                setRentAvgComplete(true)
            })
        }
    }

    const updateFurtherDetails = () => {
        let orderedDetails =[4];
        let orderedExtraDetails = [];
        for (let i=0;i < props.furtherDetails.length;i++) {
            if (props.furtherDetails[i].title === "No. of bedrooms") {
                orderedDetails[0] = props.furtherDetails[i]
                setBedrooms(props.furtherDetails[i].value)
            }
            else if (props.furtherDetails[i].title === "Property Type") {
                orderedDetails[1] = props.furtherDetails[i]
            } else if (props.furtherDetails[i].title === "No. of bathrooms") {
                orderedDetails[2] = props.furtherDetails[i]
            } else if (props.furtherDetails[i].title === "Property Size") {
                orderedDetails[3] = props.furtherDetails[i]
            } else if (props.furtherDetails[i].title === "Japanese Knotweed") {
                let _value = ""
                if (props.furtherDetails[i].value >= 99) {
                    _value = "Category A: Action"
                } else if (95 <= props.furtherDetails[i].value < 99) {
                    _value = "Category B: Action"
                } else if (80 <= props.furtherDetails[i].value < 95) {
                    _value = "Category C: Action"
                } else if (props.furtherDetails[i].value < 80) {
                    _value = "Category D: Action"
                } else {
                    _value = "No Data Available"
                }
                props.furtherDetails[i].value = _value
                orderedExtraDetails.push(props.furtherDetails[i])
            } else {
                orderedExtraDetails.push(props.furtherDetails[i])
            }
        }
        setDetails(orderedDetails)
        setExtraDetails(orderedExtraDetails)
    }

    const AssessConfidence = (accuracy) => {
        let _response = ""
        if (accuracy >= 75) {
            _response = "High"
        } else if (accuracy >= 75 && accuracy > 50) {
            _response = "Medium"
        } else {
            _response = "Low"
        }
        return _response
    }

    const updateLocalDataReady = () => {
        setLocalDataReady(true);
    }

    useEffect(() => {
        if (summaryId &&
            propertyCoords &&
            bedrooms &&
            salesAvgComplete &&
            salesData &&
            rentAvgComplete &&
            localDataReady &&
            !dataReady)
        {
            updateDataReady();
        }
    }, [summaryId, propertyCoords, bedrooms, salesAvgComplete, salesData, rentAvgComplete, localDataReady, dataReady]);

    const handleGetCreditClick = () => {
        navigate("/pricing");
    }

    return (
        <>
            {valuation ?
                <div className="insight-container">
                    {showCreditBanner ? <GetCreditBanner handleClick={handleGetCreditClick.bind(this)}/> : null }
                    <div className="valuation-insight-container">
                        <div className="valuation-insight-details-container">
                            <div className="valuation-insight-key-details-container">
                                {details ?
                                    <>
                                        <InsightKeyDetail details={details[0]} single={single} text={"bed"}/>
                                        <InsightKeyDetail details={details[1]} single={!single} text={""}/>
                                        <InsightKeyDetail details={details[2]} single={single} text={"bath"}/>
                                        <InsightKeyDetail details={details[3]} single={single} text={""}/>
                                    </>
                                    :null}
                            </div>
                            <div className="valuation-insight-values-container">
                                <div className="valuation-insight-value-range-container">
                                    <div className="valuation-insight-range-title-container">
                                        <div className="valuation-insight-range-title">
                                            Predicted range
                                        </div>
                                        <div className="valuation-insight-range-container">
                                            <div className="valuation-insight-range-bar-container">
                                                <div className="valuation-insight-range-point-container first"
                                                     style={{width: 20 + "%"}}>
                                                    <div className="valuation-insight-range-point link"/>
                                                </div>
                                                <div className="valuation-insight-real-range-container"
                                                     style={{width: 70 + "%"}}/>
                                                <div className="valuation-insight-range-point-container second"
                                                     style={{width: 20 + "%"}}>
                                                    <div className="valuation-insight-range-point link"
                                                         id="max-range-point"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="valuation-insight-whole-range-popup-container">
                                                <div className="valuation-insight-range-popup-container" style={{width: 20 + "%"}}>
                                                    <div className="valuation-insight-range-popup">
                                                        £{minRange.toLocaleString('en-GB',{maximumFractionDigits:0})}
                                                    </div>
                                                </div>
                                                <div className="valuation-insight-range-popup-container" style={{width: 20 + "%"}}>
                                                    <div className="valuation-insight-range-popup">
                                                        £{maxRange.toLocaleString('en-GB',{maximumFractionDigits:0})}
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accuracy-container insight">
                                    <div className="circle-wrap">
                                        <div className="circle">
                                            <div className="mask full actual-degree" id="full">
                                                <div className="fill actual-degree"></div>
                                            </div>
                                            <div className="mask half">
                                                <div className="fill actual-degree"></div>
                                            </div>
                                            <div className="inside-circle">
                                                <div className="accuracy-title insight">Confidence</div>
                                                <div className="accuracy-value insight">{AssessConfidence(valuation.accuracy)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="valuation-insight-historical-container">
                            <div className="valuation-insight-historical-title-container">
                                <div className="valuation-insight-historical-title">
                                    Historical value
                                </div>
                            </div>
                            <InsightHistoryLineChart data={historicData}/>
                            <div className="valuation-insight-historical-legend-container">
                                <div className="valuation-insight-historical-legend-item-container pv">
                                    <div className="valuation-insight-historical-legend-color-container">
                                        <div className="valuation-insight-historical-legend-color pv"/>
                                    </div>
                                    <div className="valuation-insight-historical-legend-text-container">
                                        <div className="valuation-insight-historical-legend-text pv">
                                            Property value
                                        </div>
                                    </div>
                                </div>
                                <div className="valuation-insight-historical-legend-item-container">
                                    <div className="valuation-insight-historical-legend-color-container avg">
                                        <div className="valuation-insight-historical-legend-color avg"/>
                                    </div>
                                    <div className="valuation-insight-historical-legend-text-container">
                                        <div className="valuation-insight-historical-legend-text">
                                            Average value for similar properties in the area
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="valuation-insight-container images">
                        <div className="valuation-insight-images-container">
                            <GoogleMapsWidget mapLocation={mapLocation}/>
                            {propertyCoords ?
                                <GoogleStreetWidget coords={propertyCoords}/>
                                :null}
                        </div>
                    </div>

                    <div className="insight-recent-title-container">
                        <div className="insight-recent-title">
                            Property details
                        </div>
                    </div>

                    <div className="valuation-insight-cards-container">
                        <div className="valuation-insight-full-cards-container">
                            {extraDetails && extraDetails.slice(0,extraDetails.length-2).map((card, idx) => {
                                return (
                                    <>
                                        <PropertyCards propertyDetails={card} key={"Property-details-card" + idx}/>
                                    </>
                                )
                                })}
                        </div>
                        <div className="valuation-insight-half-cards-container">
                            {extraDetails && extraDetails.slice(extraDetails.length-2,extraDetails.length-1).map((card, idx) => {
                                return (
                                    <>
                                        {card.title === "Flood risk score" ?
                                            <InsightKeyDetail detailsPD={card} text={"Flood risk"} key={"Key-detail-card" + idx}/>
                                            :
                                            <InsightKeyDetail detailsPD={card} text={""} key={"Key-detail-card" + idx}/>
                                        }

                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <div className="valuation-insight-correction-container">
                        <div className="valuation-insight-correction">
                            Does some information look incorrect? Get in touch at&thinsp;&thinsp;
                            <a className="valuation-insight-email" href="mailto:support@finvise.com">support@finvise.com</a>
                        </div>
                    </div>

                    {salesData ?
                        <InsightRecentActivity title={"Recent sales activity"}
                                               subTitle={""}
                                               tabs={saleTabMenus && saleTabMenus}
                                               salesAvgData={salesAvgData}
                                               salesData={salesData}
                                               predictedValue={predictedValue}/>
                        :null}
                    {rentData ?
                        <InsightRecentActivity title={"Recent rental activity"}
                                               subTitle={"Thinking of renting out? See live local rental examples"}
                                               tabs={rentTabMenus && rentTabMenus}
                                               rentData={rentData}
                                               predictedValue={predictedValue}/>
                        :null}
                    <InsightLocalArea title={"The local area"}
                                      subtitle={""}
                                      tabs={localTabMenus && localTabMenus}
                                      propertyId={propertyId}
                                      postcode={postcode}
                                      valuation={valuation}
                                      params={params}
                                      summaryId={summaryId}
                                      updateLocalDataReady={updateLocalDataReady.bind(this)}/>
                    {showCompare === false ?
                        <div className="valuation-insight-footer-button-container">
                            <div className="valuation-insight-footer-button link" onClick={handleComparison}>
                                Compare this property to another
                            </div>
                        </div>
                        :
                        <>
                            <div className="comparison-title-container">
                                <div className="comparison-title">
                                    Compare your properties
                                </div>
                            </div>
                            <CompareProperties />
                        </>
                    }
                </div>
                : null }
            {showErrorDialog ?
                <GenericDialog title="Data Error" text={error} buttons={okButton}
                               result={handleOKDialogResult.bind(this)} /> : null }
        </>
    )
}

export default InsightValuation