import {useEffect, useState} from "react";
import ValuerReviewOverview from "../components/valuer/ValuerReviewOverview";
import ValuerReviewApprove from "../components/valuer/ValuerReviewApprove";
import ValuerReviewAmend from "../components/valuer/ValuerReviewAmend";
import ValuerReviewReject from "../components/valuer/ValuerReviewReject";
import {useNavigate, useParams} from "react-router-dom";
import valuer_api from "../api/valuer";
import MainHeader from "../components/MainHeader";
import ValuerReviewInfo from "../components/valuer/ValuerReviewInfo";
import ValuationStatusPill from "../components/ValuationStatusPill";
import val_api from "../api/valuation";
import profapi from "../api/profile";
import ValuerChangeLog from "../components/valuer/ValuerChangeLog";
import GenericDialog from "../components/GenericDialog";

const ValuerReview = () => {
    const OVERVIEW_SECTION = 0;
    const APPROVE_SECTION = 1;
    const AMEND_SECTION = 2;
    const REJECT_SECTION = 3;

    const params = useParams();
    const navigate = useNavigate();

    const [report, setReport] = useState();
    const [summaryId, setSummaryId] = useState();
    const [sections, setSections] = useState();
    const [activeSection, setActiveSection] = useState(OVERVIEW_SECTION);
    const [profile, setProfile] = useState();
    const [roleId, setRoleId] = useState();

    const [history, setHistory] = useState();
    const [showChangeLog, setShowChangeLog] = useState(false);

    const [modifications, setModifications] = useState()

    const [error, setError] = useState("")
    const [showErrorDialog, setShowErrorDialog] = useState(false)
    const okButton = [{"text":"OK", "id": "1"}];

    const [reportGeneration, setReportGeneration] = useState(false)


    const getPropertyModifications = () => {
        if (!summaryId) return;
        if (!report) return;
        if (report.summary_status_id === 4) return;
        if (!report.assigned_match) return;
        let response = valuer_api.getPropertyModifications(summaryId);
        response.then(
            response => {
                setModifications(response.data)
            }
        ).catch((err) => {
            console.log(err);
        })
    }

    const getSections = () => {
        let items = [];
        let item = {};
        item.id = OVERVIEW_SECTION;
        item.name= "Overview";
        item.active = true;
        item.enabled = true;
        items.push(item);

        item = {};
        item.id = APPROVE_SECTION;
        item.name = "Approve";
        item.active = false;
        item.enabled = true;
        items.push(item);

        item = {};
        item.id = AMEND_SECTION;
        item.name = "Amend";
        item.active = false;
        item.enabled = false;
        items.push(item);

        item = {};
        item.id = REJECT_SECTION;
        item.name = "Reject";
        item.active = false;
        item.enabled = true;
        items.push(item);

        setSections(items);
    }

    const updateEnabledSections = () => {
        if (!sections) return;
        if (!report) return;
        let updateSections = [...sections];
        if (report.assigned_match) {
            switch(report.summary_status_id){
                case 1:
                    updateSections[OVERVIEW_SECTION].enabled = true;
                    updateSections[APPROVE_SECTION].enabled = true;
                    updateSections[AMEND_SECTION].enabled = false;
                    updateSections[REJECT_SECTION].enabled = true;
                    break;
                case 2:
                case 3:
                case 4:
                case 5:
                    updateSections[OVERVIEW_SECTION].enabled = true;
                    updateSections[APPROVE_SECTION].enabled = false;
                    updateSections[AMEND_SECTION].enabled = false;
                    updateSections[REJECT_SECTION].enabled = false;
                    break;
            }
        } else {
            updateSections[OVERVIEW_SECTION].enabled = true;
            updateSections[APPROVE_SECTION].enabled = false;
            updateSections[AMEND_SECTION].enabled = false;
            updateSections[REJECT_SECTION].enabled = false;
        }
        setSections(updateSections);
    }

    const getHistory = () => {
        if (!summaryId) return;
        const response = val_api.getValuationHistory(summaryId);
        response.then(
            response => {
                setHistory((response.data.history).reverse());
            }
        ).catch(err => {
                console.log(err);
            }
        )
    }

    useEffect(() => {
        if (!roleId) return;
        if (!summaryId) return;
        if (!history) return;
        if (activeSection !== 0) return;
    },[roleId, summaryId, history, activeSection])

    useEffect(() => {
        if (!summaryId) return;
        getHistory()
    }, [summaryId])

    const getTabClass = (tab) => {
        let classname = "valuer-review-content-head link";

        if (tab.active){
            classname += " active";
        }

        if (tab.enabled) {
            classname += " enabled";
        }

        return classname;
    }

    const getProfile = () => {
        let response = profapi.getProfile();
        response.then(
            response => {
                setProfile(response.data);
                setRoleId(response.data.role_id)
            }
        )
    }

    const getReport = () => {
        if (!params.id) return;
        let response = valuer_api.getReport(params.id);
        response.then(
            response => {
                setReport(response.data);
                setSummaryId(response.data.summary_id)
            }
        ).catch((err) => {
            console.log(err);
        })
    }

    const updateReport = () => {
        getReport();
    }

    useEffect(() => {
        getReport()
        getSections()
        getProfile()
    }, [])

    useEffect(() => {
        if (!report) return;
        updateEnabledSections();
        if (report.summary_status_id === 4) return;
        getPropertyModifications();
    }, [report])

    useEffect(() => {
        if (!modifications) return;
        if (!sections) return;
        if (!report) return;
        if (report.summary_status_id === 4) return;
        let updateSections = [...sections];
        updateSections[AMEND_SECTION].enabled = true;
        setSections(updateSections);
    },[modifications])

    function handleHeadingClick(ev) {
        if (!ev.target.id) return;
        let newSections = [...sections];
        if (newSections[parseInt(ev.target.id)].enabled){
            for(let i=0;i<newSections.length;i++){
                if (i === parseInt(ev.target.id)){
                    newSections[i].active = true;
                    setActiveSection(i);
                }
                else {
                    newSections[i].active = false;
                }
            }
            setSections(newSections);
        }

    }

    const updateChangeLog = () => {
        setShowChangeLog(true);
    }

    function handleBackClick() {
        navigate("/valuer");
    }

    const handleChangeLockBack = () => {
        setShowChangeLog(false);
    }

    function handlePullReportClick() {
        if (!report) return;
        const response = val_api.setUnderReview(report.summary_id);
        response.then(
            response => {
                setShowErrorDialog(true)
                setError("The report has been set as Under review.")
                updateReport();
            }
        ).catch(err => {
            setShowErrorDialog(true)
            setError( err.response.statusText
                ? "There was an error pulling the report and setting it as under review. " + err.response.statusText
                : "There was an error pulling the report and setting it as under review.")
        })
    }

    function handleReopenClick() {
        if (!report) return;
        const response = val_api.setReopen(report.summary_id);
        response.then(
            response => {
                updateReport();
            }
        ).catch(err => {
            console.log(err);
        })
    }

    const updateGeneration = () => {
        setReportGeneration(!reportGeneration)
    }

    return (
        <>
            <MainHeader />
            <div className="valuer-review-page-container">
                {showChangeLog ?
                    <>
                        <ValuerChangeLog address={report && report.address} history={history}
                                         clickBack={handleChangeLockBack.bind(this)}/>
                    </>
                    :
                    <>
                        <div className="valuer-review-page-title">
                            <div className="valuer-review-back link" onClick={handleBackClick}>{"<"}</div>
                            <div className="valuer-review-address">{report && report.address + ", " + report.postcode}</div>
                            {report && (report.summary_status_id === 4 || report.summary_status_id === 5) ?
                                <div className="valuer-overview-button-container">
                                    <div className="valuer-overview-button link" onClick={handleReopenClick}>
                                        Pull report and set as 'Under review'
                                    </div>
                                </div>
                                : null }
                            <ValuationStatusPill status_id={report && report.summary_status_id}
                                status_name={report && report.summary_status_name}/>
                        </div>

                        <div className="valuer-review-container">
                            <div className="valuer-review-content-header">
                                {sections && sections.map((tab, idx) => {
                                    return (
                                        <div className={getTabClass(tab)} id={idx}
                                             onClick={handleHeadingClick}>{tab.name}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="valuer-review-content-container">
                                <div className="valuer-review-section-container">
                                    {activeSection === OVERVIEW_SECTION ? <ValuerReviewOverview history={history} report={report}
                                                                               profile={profile} summaryId={summaryId}
                                                                                updateHistory={getHistory.bind(this)}/>
                                        : null}
                                    {activeSection === APPROVE_SECTION ? <ValuerReviewApprove report={report}
                                                                                              reportGeneration={reportGeneration}/>
                                        : null}
                                    {activeSection === AMEND_SECTION ? <ValuerReviewAmend report={report}
                                                                                          modifications={modifications}
                                                                                          reportGeneration={reportGeneration}/>
                                        : null}
                                    {activeSection === REJECT_SECTION ? <ValuerReviewReject report={report} />
                                        : null}
                                </div>
                                <ValuerReviewInfo report={report}
                                                  updateReport={updateReport.bind(this)}
                                                  clickChangeLog={updateChangeLog.bind(this)}
                                                  reportGeneration={reportGeneration}
                                                  updateGeneration={updateGeneration.bind(this)}
                                />
                            </div>
                        </div>
                    </>
                }
            </div>
            {showErrorDialog
                ? <GenericDialog title="Valuation" text={error} buttons={okButton}
                                 result={() => setShowErrorDialog(false)} />
                : null
            }
        </>
    )
}

export default ValuerReview