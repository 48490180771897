import {useEffect, useRef, useState} from "react";
import address_api from "../api/address";
import property_api from "../api/property"
import search from "../assets/search.png";
import styles from "../models/Styles";
import format from "../utils/Address";
import ValuationTypeDropdown from "./ValuationTypeDropdown";
import roles from "../models/Roles";
import dropdown from "../assets/dropdown.png";
import val_api from "../api/valuation";
import AddressLookupFlyout from "./AddressLookupFlyout";
import ReportTypesFlyout from "./ReportTypesFlyout";
import arrow from "../assets/arrow_forwards.png"
import SearchDetailRow from "./SearchDetailRow";
import ManualAddressPopup from "./ManualAddressPopup";

const ValuationAddressSearch = (props) => {
    const evaluate = props.evaluate;
    const clear = props.clear;
    const resetSearch = props.resetSearch;
    const profile = props.profile;
    const handleManualPopup = props.handleManualPopup;
    const addressListRef = useRef();

    const [displayAddress, setDisplayAddress] = useState("");
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [valuationType, setValuationType] = useState(null);

    const [listAddresses, setListAddresses] = useState([]);
    const [showListAddresses, setShowListAddresses] = useState(false);
    const [showNoProperties, setShowNoProperties] = useState(false);
    const [onlyPostcodes, setOnlyPostcodes] = useState(false)

    const [showInfoPanel, setShowInfoPanel] = useState(false);
    const [showPageOverlay, setShowPageOverlay] = useState(false);

    const [selectedType, setSelectedType] = useState(null)
    const [evaluationTypes, setEvaluationTypes] = useState([])

    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [noData, setNoData] = useState(false)

    const [selectedPurpose, setSelectedPurpose] = useState("")
    const [reportPurpose, setReportPurpose] = useState([])
    const [purposeOpen, setPurposeOpen] = useState(false)

    const [showDetails, setShowDetails] = useState(false)
    const [bedrooms, setBedrooms] = useState("-")
    const [bathrooms, setBathrooms] = useState("-")
    const [livingRooms, setLivingRooms] = useState("-")
    const [size, setSize] = useState(0)

    const [showManualPopup, setShowManualPopup] = useState(false)

    const details = [{id: 1, name:"Bedrooms"},
                                                {id: 2, name:"Bathrooms"},
                                                {id: 3, name:"Living rooms"},
                                                {id: 4, name:"Sq ft"}]

    useEffect(() => {
        getValuationTypes()
        getValuationPurpose()
    }, []);

    useEffect(() => {
        setSelectedType(valuationType)
        setSelectedPurpose(null)
    }, [valuationType]);

    const getValuationTypes = () => {
        let response = val_api.getValuationTypes();
        response.then(response => {
            setEvaluationTypes(response.data.types);
        })
    }

    const getValuationPurpose = () => {
        let response = val_api.getValuationPurpose();
        response.then(response => {
            setReportPurpose(response.data.types)
        }).catch(err => console.log(err))
    }

    const getCurrentName = () => {
        for (const item of evaluationTypes) {
            if (item.id === selectedType) return item.name
        }
        return "Select valuation type"
    }

    const getCurrentPurpose = () => {
        for (const item of reportPurpose) {
            if (item.id === selectedPurpose) return item.name
        }
        return "Report Purpose"
    }

    const handleItemClick = (_event) => {
        if (!_event) return;
        setValuationType(parseInt(_event.target.id))
        setDropdownOpen(false)
    }

    const handlePurposeClick = (ev) => {
        if(!ev) return;
        setSelectedPurpose(parseInt(ev.target.id))
        setPurposeOpen(false)
    }

    const handleClickOutsideAddressList = (ev) => {
        if (addressListRef.current || !addressListRef.current.contains(ev.target)){
            setShowListAddresses(false);
        }
    }

    const handleRequestValuation = async () => {
        if ((!selectedProperty && !noData) || !valuationType) return;
        let _bedrooms = bedrooms
        if (_bedrooms === "5+") {
            _bedrooms = 5
        }
        let _details = {
            bedrooms: _bedrooms,
            bathrooms: bathrooms,
            living_rooms: livingRooms,
            size: parseInt(size),
            kitchens: "-",
            rent: 0,
            extra_details: ""
        }
        evaluate(selectedProperty, displayAddress, valuationType, noData, selectedPurpose, _details);
        setBedrooms("-")
        setBathrooms("-")
        setLivingRooms("-")
        setSize(0)
        // Reset values
        setShowInfoPanel(false);
        setSelectedProperty(null);
        setDisplayAddress("");
        setValuationType(null);
    }

    // Used For OLD Method
    const handlePostcodeLookup = () => {
        if (!displayAddress){
            setShowListAddresses(false);
            return;
        }
        if (displayAddress.length >= 11) {
            setDisplayAddress("")
        }
        setShowNoProperties(false);
        setShowListAddresses(false);
        setSelectedProperty(null);

        SearchAddress()
    }

    const  handlePostcodeLookupTemp = () => {
        if (!isValidPostcode(displayAddress)) {
            setShowListAddresses(false);
            setShowNoProperties(false);
            setSelectedProperty(null);
            setNoData(false);
            return;
        }
        if (displayAddress.length >= 11) {
            setDisplayAddress("")
        }
        SearchAddress()
    }

    const isValidPostcode = (postcode)  => {
        if (!postcode) return
        let regexp = /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/;
        return (regexp.test(postcode.replace(" ", "")))
    }

    const SearchAddress = (_address) => {
        if (!_address) _address = displayAddress
        setShowListAddresses(true);
        let response = address_api.searchAddress(_address);
        response.then(response => {
            setListAddresses(response.data.properties);
            if (response.data.properties.length > 0) {
                setOnlyPostcodes(response.data.postcodes_only);
            }
            else {
                setShowNoProperties(true);
            }
        })
    }

    const selectAddressTemp = (event) => {
        if (!event || !listAddresses) return;
        setDisplayAddress(listAddresses[event.target.id].address);
        setSelectedProperty(null);
        setShowListAddresses(false);
        setListAddresses(null);
        setShowInfoPanel(false)
        getPropertyIDTemp( listAddresses[event.target.id].postcode, listAddresses[event.target.id].line_1)
    }

    const getPropertyIDTemp = (postcode, address) => {
        if (!postcode || !address) return;
        let response = property_api.getPropertyID(postcode, address);
        response.then(response => {
            if (!response.data.property_id) setNoData(true)
            else {
                setSelectedProperty(response.data.property_id)
                setNoData(false)
            }
        })
    }

    const selectAddress = (event) => {
        if (!event || !listAddresses) return;
        setDisplayAddress(format.formatSearchAddress(listAddresses[event.target.id]));
        setSelectedProperty(listAddresses[event.target.id].property_id);
        setShowListAddresses(false);
        setListAddresses(null);
        setShowInfoPanel(false)
    }

    const selectPostcode = (event) => {
        if (!event || !listAddresses) return;
        setDisplayAddress(listAddresses[event.target.id].postcode.toUpperCase());
        SearchAddress(listAddresses[event.target.id].postcode.toUpperCase())
        setListAddresses([]);
    }

    const handleClick = () => {
        setShowInfoPanel(true);
        resetSearch();
        handlePostcodeLookupTemp();
    }

    useEffect(() => {
        // Clear address whenever this value changes
        if (clear) setDisplayAddress(null);
    }, [clear])

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideAddressList, true);
        return () => {
            document.removeEventListener('click', handleClickOutsideAddressList, true);
        }
    }, [])

    useEffect(() => {
        if (!profile) return;
        if (profile.role_id === roles.ROLE_RETAIL_USER){
            // Fix type for B2C to be insight
            setValuationType(3);
        }
    }, [profile]);

    const handleLinkClick = (_id) => {
        let element = document.getElementById(_id);
        if (!element) return;

        // scroll the window to the top for the fly out
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        // switch class closed for open
        element.classList.add("open");
        element.classList.remove("closed");
        setShowPageOverlay(true);
    }

    const handleLinkHide = (_id) => {
        let element = document.getElementById(_id);
        if (!element) return;
        element.classList.add("closed");
        element.classList.remove("open");
        setShowPageOverlay(false);
    }

    const handleIncrement = (id) => {
        if (!id) return;
        switch (id) {
            case 1:
                if (bedrooms === "5+") return;
                if (bedrooms === 5) {
                    setBedrooms("5+")
                } else if (bedrooms === "-") {
                    setBedrooms(0)
                } else {
                    setBedrooms(bedrooms + 1)
                }
                break;
            case 2:
                if (bathrooms === "5+") return;
                if (bathrooms === 5) {
                    setBathrooms("5+")
                } else if (bathrooms === "-") {
                    setBathrooms(0)
                } else {
                    setBathrooms(bathrooms + 1)
                }
                break;
            case 3:
                if (livingRooms === "-") {
                    setLivingRooms(0)
                } else {
                    setLivingRooms(livingRooms + 1)
                }
                break;
        }
    }

    const handleReduction = (id) => {
        if (!id) return;
        switch (id) {
            case 1:
                if (bedrooms === "-") return;
                if (bedrooms === "5+") {
                    setBedrooms(5)
                    return;
                } else if (bedrooms === 0) {
                    setBedrooms("-")
                    return;
                } else {
                    setBedrooms(bedrooms - 1)
                }
                break;
            case 2:
                if (bathrooms === "-") return;
                if (bathrooms === "5+") {
                    setBathrooms(5)
                    return;
                } else if (bathrooms === 0) {
                    setBathrooms("-")
                    return;
                } else {
                    setBathrooms(bathrooms - 1)
                }
                break;
            case 3:
                if (livingRooms === "-") return;
                if (livingRooms === 0) {
                    setLivingRooms("-")
                    return;
                } else {
                    setLivingRooms(livingRooms - 1)
                }
                break;
        }
    }

    const handleSizeChange = (ev) => {
        let _value = Number(ev.target.value.replace(/[^0-9.-]+/g, ""));
        if (_value > 1000000) {
            _value = 999999
        }
        if (_value === "") {
            _value = null
        }
        setSize(_value)
    }

    const getRowClassName = (obj, array) => {
        let _classname = "valuation-type-option-container middle"
        if (!obj || !array) return _classname
        let _length = array.length
        if (obj === array[0]) {
            _classname = "valuation-type-option-container first"
        } else if (obj === array[_length - 1]) {
            _classname = "valuation-type-option-container last"
        }
        return _classname
    }

    const getOptionClassName = (obj, array) => {
        let _classname = "valuation-type-option middle"
        if (!obj || !array) return _classname
        let _length = array.length
        if (obj === array[0]) {
            _classname = "valuation-type-option first"
        } else if (obj === array[_length - 1]) {
            _classname = "valuation-type-option last"
        }
        return _classname
    }

    return (
        <>
            <form className="valuation-form" autoComplete="off" id="address-search-form">
                <div className="field-input">
                    {profile && profile.role_id === roles.ROLE_RETAIL_USER ?
                        <div className="label">New market insight</div> :
                        <div className="label">New valuation request</div>}
                    <div className="valuation-address-container">
                        <div className="valuation-address-bar">
                            <div className="valuation-address-entry">
                                {profile && profile.role_id !== roles.ROLE_RETAIL_USER ?
                                    <>
                                        <div className="valuation-type-dropdown-ancor">
                                            <div className="valuation-type-container borderless">
                                                <div className="valuation-type-title">
                                                    Report type*
                                                </div>
                                                <div className="valuation-type"
                                                     onClick={() => setDropdownOpen(!dropdownOpen)}>
                                                    {selectedType ? getCurrentName() : "Select valuation type"}
                                                    {/*<img className="valuation-type-dropdown-icon" src={dropdown}
                                                             alt="Dropdown arrow"/>*/}
                                                </div>
                                            </div>
                                            {dropdownOpen ?
                                                <>
                                                    <div className="valuation-type-dropdown-options">
                                                        {evaluationTypes && evaluationTypes.map((type) => (
                                                            <div className={getRowClassName(type, evaluationTypes)} >
                                                                <div className={getOptionClassName(type, evaluationTypes)} id={type.id}
                                                                     key={type.id} onClick={handleItemClick}>
                                                                    {type.name}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="invisible-page-overlay"
                                                         onClick={() => setDropdownOpen(false)}/>
                                                </> : null
                                            }
                                        </div>
                                    </>
                                    : null
                                }
                                <div className="valuation-type-container">
                                    <div className="valuation-type-title">
                                        Address*
                                    </div>
                                    <input type="text" className="valuation-address-text" value={displayAddress}
                                           onClick={handleClick} id="address-search"
                                           onChange={(ev) => setDisplayAddress(ev.target.value.toUpperCase())}
                                           placeholder="Type in postcode"
                                           maxLength="10" onKeyUp={handlePostcodeLookup}
                                           aria-label="Address search by postcode" data-1p-ignore data-lpignore
                                           disabled={selectedType === null}/>
                                </div>
                                {profile && profile.role_id !== roles.ROLE_RETAIL_USER ?
                                    <>
                                        <div className="valuation-type-dropdown-ancor">
                                            <div className='valuation-type-container small-container'>
                                                    <div className="valuation-type-title">
                                                        {selectedType === 2 ? "Report purpose*" : "Report purpose"}
                                                    </div>
                                                    <div className={selectedType === 2 ? "valuation-type" : "valuation-type disabled"}
                                                         onClick={() => {
                                                             if(selectedType === 2) setPurposeOpen(!purposeOpen)
                                                         }}>
                                                        {selectedPurpose ? getCurrentPurpose() : "Select purpose"}
                                                        {/*<img className="valuation-type-dropdown-icon" src={dropdown}
                                                             alt="Dropdown arrow"/>*/}
                                                    </div>
                                            </div>
                                            {purposeOpen ?
                                                <>
                                                    <div className="valuation-type-dropdown-options">
                                                        {reportPurpose && reportPurpose.map((type, idx) => (
                                                            <div className={getRowClassName(type, reportPurpose)} >
                                                                <div className={getOptionClassName(type, reportPurpose)} id={type.id}
                                                                     key={type.id} onClick={handlePurposeClick}>
                                                                    {type.name}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="invisible-page-overlay"
                                                             onClick={() => setPurposeOpen(false)}/>
                                                </> : null
                                            }
                                        </div>
                                        <div className="valuation-type-dropdown-ancor">
                                            <div className='valuation-type-container small-container'>
                                                <div className="valuation-type-title">
                                                    Property details
                                                </div>
                                                <div className="valuation-type" onClick={() => {setShowDetails(!showDetails)}}>
                                                    {bedrooms !== "-" || bathrooms !== "-" || livingRooms !== "-" || size !== 0 ?
                                                        <div className="valuation-type-text">Details added</div>
                                                        :
                                                        <div className="valuation-type-text">Know the property?</div>
                                                    }
                                                </div>
                                            </div>
                                            {showDetails ?
                                                <>
                                                    <div className="valuation-type-dropdown-options">
                                                        <SearchDetailRow value={bedrooms}
                                                                         detail={details[0]}
                                                                         increment={handleIncrement.bind(this)}
                                                                         reduction={handleReduction.bind(this)}
                                                        />
                                                        <SearchDetailRow value={bathrooms}
                                                                         detail={details[1]}
                                                                         increment={handleIncrement.bind(this)}
                                                                         reduction={handleReduction.bind(this)}
                                                        />
                                                        <SearchDetailRow value={livingRooms}
                                                                         detail={details[2]}
                                                                         increment={handleIncrement.bind(this)}
                                                                         reduction={handleReduction.bind(this)}
                                                        />
                                                        <div className="valuation-type-option-container counter last" id={details[3].id}
                                                             key={details[3].id}>
                                                            <div className="valuation-type-option counter last">
                                                                <div className="valuation-type-option-title">
                                                                    {details[3].name}
                                                                </div>
                                                                <input type="text" id={details[3].id} value={size && size.toLocaleString()} onChange={handleSizeChange}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="invisible-page-overlay"
                                                         onClick={() => setShowDetails(false)}/>
                                                </> : null
                                            }
                                        </div>
                                    </> : null
                                }
                                <input type="button" className={!((selectedProperty || noData) && valuationType)
                                    || valuationType === 2 && !selectedPurpose ? "valuation-address-button" : "valuation-address-button disabled"}
                                       value={profile && profile.role_id !== roles.ROLE_RETAIL_USER ? "+ Get Report" : "+ Get insight"}
                                       onClick={handleRequestValuation}
                                       disabled={!((selectedProperty || noData) && valuationType) || valuationType === 2 && !selectedPurpose}
                                />
                            </div>
                        </div>
                        <div className="valuation-address-list-flex"
                             style={showListAddresses ? styles.show : styles.hide}>
                            <div className="valuation-address-list-empty"/>
                            <div className="valuation-address-list-container" style={showListAddresses ? styles.show : styles.hide}
                                 ref={addressListRef} id="scrollbar3">
                                <div className="valuation-address-list" id="scrollbar3" >
                                    {listAddresses && listAddresses.map((address, idx) => {
                                        return (
                                            <>
                                                {onlyPostcodes ?
                                                    <div className="valuation-list-address link" id={idx} key={idx}
                                                         onClick={selectPostcode}>
                                                        {format.formatPostCodeAndCount(address)}
                                                    </div>
                                                    :
                                                    <div className="valuation-list-address link" id={idx} key={idx}
                                                         onClick={selectAddress}>
                                                        {format.formatSearchAddress(address)}
                                                        {/*{address.address && address.address}*/}
                                                    </div>
                                                }
                                            </>
                                        )
                                    })}
                                </div>
                                <div className="valuation-list-address flex link"
                                         onClick={() => {
                                             handleManualPopup(true)
                                             setShowInfoPanel(false)
                                         }}>
                                        Can't find the address you're looking for?
                                        <div className="valuation-list-button-container">
                                            Submit manual address
                                            <img src={arrow} alt={">"}/>
                                        </div>
                                    </div>
                            </div>
                            <div className="valuation-address-list-empty small"/>
                        </div>
                    </div>
                </div>
            </form>
            <div className="valuation-link-container">
                {profile && profile.role_id !== roles.ROLE_RETAIL_USER ?
                    <div className="valuation-address-help-link-container">
                        <div className="valuation-type-link"
                             onClick={() => handleLinkClick("types-flyout-container")}>
                            What are the different report types?
                        </div>
                    </div>
                    : null}
                <div className="valuation-address-indicator-text">*Indicates mandatory field</div>
            </div>

            <AddressLookupFlyout hide={handleLinkHide.bind(this)}/>
            <ReportTypesFlyout hide={handleLinkHide.bind(this)}/>


            {showPageOverlay && showPageOverlay ?
                <div className="page-overlay" onClick={() => {
                    handleLinkHide("types-flyout-container")
                    handleLinkHide("address-flyout-container")}
                } /> : null}

            {showNoProperties ?
                <div className="valuation-address-link-container">
                    <div className="valuation-address-no-properties">{displayAddress} - no properties found</div>
                </div>
                : null}
        </>
    )
}

export default ValuationAddressSearch