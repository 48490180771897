import close from "../assets/Close.png";
const ReportTypesFlyout = (props) => {
    const hide = props.hide;
    return (
        <>
            <div className="report-types-flyout-container closed" id="types-flyout-container">
                <div className="report-types-flyout-header">
                    <div className="report-types-flyout-header-text">What are the different report types?</div>
                    <div className="report-types-flyout-close link" onClick={() => hide("types-flyout-container")}>
                        <img src={close} alt={"Close Tab"}/>
                    </div>
                </div>
                <div className="report-types-flyout-content">
                    <p>FINVISE valuation report</p>
                    <p>An instant valuation of the property generated by FINVISE’s proprietary artificial intelligence
                        algorithms, combined with additional property details and insights. </p>
                    <br/><br/>
                    <p>RICS regulated valuation report</p>
                    <p>A property valuation prepared by the FINVISE hybrid valuation model and internally
                        audited by a RICS-qualified surveyor.</p>
                    <br/><br/>
                    <p>Market insight report</p>
                    <p>A detailed dashboard and report including historical value, property details,
                        local market averages, sales and rental data, and nearby amenities for a comprehensive
                        analysis of the property and its area.</p>
                </div>

            </div>
        </>
    )
}

export default ReportTypesFlyout