import val_api from "../../api/valuation";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import ButtonLoadingWheel from "../ButtonLoadingWheel";

const ValuerReviewReject = (props) => {
    const report = props.report;
    const navigate = useNavigate();

    const [processing, setProcessing] = useState(false)

    function handleRejectClick() {
        if (!report) return;
        if (processing) return;
        setProcessing(true)
        let response = val_api.setRejected(report.summary_id);
        response.then(
            response => {
                setProcessing(false)
                navigate("/valuer");
            }
        ).catch(
            err => {
                setProcessing(false)
                console.log(err);
            }
        )
    }

    return (
        <>
            <div className="valuer-reject-container">
                <div className="valuer-reject-text">
                    Unable to give a reasonable valuation?
                </div>
                <div className="valuer-reject-text">
                    Make sure you follow all internal guidelines and fill out any appropriate documentation that has been requested of you before rejecting this valuation.
                </div>
                <div className="valuer-reject-text large">
                    Upon rejecting this RICS valuation, the client’s ticket will be closed.
                </div>
                <div className="valuer-reject-button-container">
                    <div className={processing ? "valuer-reject-button" : "valuer-reject-button link"} onClick={handleRejectClick}>
                        {processing ?
                            <div className="valuer-submit-container" style={{width: "8%"}}>
                                <ButtonLoadingWheel show={processing}/>
                            </div>
                            :
                            <>Reject and close valuation</>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ValuerReviewReject