import {useEffect, useState} from "react";
import val_api from "../../api/valuation";
import {useNavigate} from "react-router-dom";
import ValuerAmendIntCard from "./ValuerAmendIntCard";
import ValuerAmendDropdownCard from "./ValuerAmendDropdownCard";
import ValuerAmendStrCard from "./ValuerAmendStrCard";
import ImageUtils from "../../utils/ImageUtils";
import ValuerAmendCurrencyCard from "./ValuerAmendCurrencyCard";
import ValuerReviewAppendix from "./ValuerReviewAppendix";
import GenericDialog from "../GenericDialog";
import ButtonLoadingWheel from "../ButtonLoadingWheel";

const ValuerReviewAmend = (props) => {
    const CONFIDENT = 1;
    const VERY_CONFIDENT = 2;

    // Error Handling
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [error, setError] = useState("");
    const okButton = [{"text":"OK", "id": "1"}];

    const [processing, setProcessing] = useState(false)

    const report = props.report;
    const reportGeneration = props.reportGeneration
    const navigate = useNavigate();
    const [newValue, setNewValue] = useState();
    const [overviewComment, setOverviewComment] = useState();
    const [overviewCounter, setOverviewCounter] = useState(0)
    const [remarks, setRemarks] = useState();
    const [remarksCounter, setRemarksCounter] = useState(0)
    const [specialComment, setSpecialComment] = useState();
    const [specialCounter, setSpecialCounter] = useState(0)
    const [confidence, setConfidence] = useState(CONFIDENT);
    const modifications = props.modifications

    const [size, setSize] = useState(0)
    const [type, setType] = useState()
    const [EPCrating, setEPCrating] = useState(0)
    const [bedrooms, setBedrooms] = useState()
    const [bathrooms, setBathrooms] = useState()
    const [livingRooms, setLivingRooms] = useState(0)
    const [knotweed, setKnotweed] = useState()
    const [knotweedValue, setKnotweedValue] = useState(0)
    const [floodRisk, setFloodRisk] = useState({})
    const [newBuild, setNewBuild] = useState(false)
    const [tenure, setTenure] = useState("")
    const [floors, setFloors] = useState(0)
    const [titleNumber, setTitleNumber] = useState("")
    const [kitchen, setKitchen] = useState("")

    const [date, setDate] = useState()
    const [soldValue, setSoldValue] = useState(0)
    const [predictedValue, setPredictedValue] = useState(0)
    const [algAccuracy, setAlgAccuracy] = useState(0)

    const [pdfBase64, setPdfBase64] = useState("")
    const [fileType, setFileType] = useState("")
    const [showPrevious, setShowPrevious] = useState(false)
    const [showUpload, setShowUpload] = useState(true)

    const [asterisk, setAsterisk] = useState(false)

    // Rental values
    const [graphB64, setGraphB64] = useState("")
    const [addGraph, setAddGraph] = useState(false)
    const [rentFreeText, setRentFreeText] = useState("")
    const [marketRent, setMarketRent] = useState("")
    const [grossRental, setGrossRental] = useState("")
    const [comparableRental, setComparableRental] = useState("")
    const [transactionsPM, setTransactionPM] = useState("")
    const [rentalTurnover, setRentalTurnover] = useState("")
    const [rentalInventory, setRentalInventory] = useState("")
    const [rentalDays, setRentalDays] = useState("")
    const [rentalDemand, setRentalDemand] = useState("")

    // Sales values
    const [salesFreeText, setSalesFreeText] = useState("")
    const [totalSale, setTotalSale] = useState("")
    const [avgSales, setAvgSales] = useState("")
    const [salesTurnover, setSalesTurnover] = useState("")
    const [salesInventory, setSalesInventory] = useState("")
    const [salesDays, setSalesDays] = useState("")
    const [salesDemand, setSalesDemand] = useState("")

    // Additional values
    const [additionalFreeText, setAdditionalFreeText] = useState("")
    const [conservationArea, setConservationArea] = useState("")
    const [listedBuildings, setListedBuildings] = useState("")
    const [constructionMethod, setConstructionMethod] = useState("")
    const [completionYear, setCompletionYear] = useState("")
    const [taxBand, setTaxBand] = useState("")

    // Reinstatement values
    const [reinstatementFreeText, setReinstatementFreeText] = useState("")
    const [areaUnit, setAreaUnit] = useState("")
    const [clearance, setClearance] = useState("")
    const [rebuildCost, setRebuildCost] = useState("")
    const [profFees, setProfFees] = useState("")
    const [externalWork, setExternalWork] = useState("")
    const [reinstatementTotal, setReinstatementTotal] = useState("")

    // General additional comments
    const [generalComment, setGeneralComment] = useState("")

    // Images for appendix
    const [files, setFiles] = useState([])

    const build_options = [{id: 1, name:"Not new build"}, {id:2, name:"New build"}]

    const knotweed_options = [
        {id: 1, name:"None"},
        {id: 2, name:"Category D: Report"},
        {id: 3, name:"Category C: Manage"},
        {id: 4, name:"Category B: Action"},
        {id: 5, name:"Category A: Action"}]

    const bed_bath_options = [
        {id: 1, name:"-"},
        {id: 2, name:"0"},
        {id: 3, name:"1"},
        {id: 4, name:"2"},
        {id: 5, name:"3"},
        {id: 6, name:"4"},
        {id: 7, name:"5"},
        {id: 8, name:"5+"}
    ]

    const rental_info = [
        {id: 1, type: 1, title: "[Free text field]:", value: rentFreeText},
        {id: 2, type: 1, title: "Market Rent:", value: marketRent},
        {id: 3, type: 1, title: "Gross Rental Yield:", value: grossRental},
        {id: 4, type: 1, title: "Comparable Rental PCM High & Low:", value: comparableRental},
        {id: 5, type: 1, title: "Transactions Per Month:", value: transactionsPM},
        {id: 6, type: 1, title: "Turnover Per Month:", value: rentalTurnover},
        {id: 7, type: 1, title: "Months of Inventory:", value: rentalInventory},
        {id: 8, type: 1, title: "Days on Market:", value: rentalDays},
        {id: 9, type: 1, title: "Rental Demand Rating:", value: rentalDemand}
    ]

    const sales_info = [
        {id: 1, type: 2, title: "[Free text field]:", value: salesFreeText},
        {id: 2, type: 2, title: "Total for Sale:", value: totalSale},
        {id: 3, type: 2, title: "Average Sales Per Month:", value: avgSales},
        {id: 4, type: 2, title: "Turnover Per Month:", value: salesTurnover},
        {id: 5, type: 2, title: "Months of Inventory:", value: salesInventory},
        {id: 6, type: 2, title: "Days on Market:", value: salesDays},
        {id: 7, type: 2, title: "Demand Rating:", value: salesDemand}
    ]

    const additional_info = [
        {id: 1, type: 3, title: "[Free text field]:", value: additionalFreeText},
        {id: 2, type: 3, title: "Conservation Area:", value: conservationArea},
        {id: 3, type: 3, title: "Listed Building:", value: listedBuildings},
        {id: 4, type: 3, title: "Construction Method:", value: constructionMethod},
        {id: 5, type: 3, title: "Year of Completion:", value: completionYear},
        {id: 6, type: 3, title: "Council Tax Band:", value: taxBand}
    ]

    const reinstatement_info = [
        {id: 1, type: 4, title: "[Free text field]:", value: reinstatementFreeText},
        {id: 2, type: 4, title: "Area unit:", value: areaUnit},
        {id: 3, type: 4, title: "Clearance:", value: clearance},
        {id: 4, type: 4, title: "Rebuild cost:", value: rebuildCost},
        {id: 5, type: 4, title: "Professional fees:", value: profFees},
        {id: 6, type: 4, title: "External works:", value: externalWork},
        {id: 7, type: 4, title: "Total:", value: reinstatementTotal}
    ]

    useEffect(() => {
        setOverviewComment(modifications.overview_remarks)
        setRemarks(modifications.remarks)
        if (modifications.remarks.length) {
            setRemarksCounter(modifications.remarks.length)
        }
        setSpecialComment(modifications.special_remarks)
        setSize(modifications.size.value)
        setType(modifications.type.value)
        setEPCrating(modifications.epc_rating.value)
        setBedrooms(modifications.bedrooms.value)
        setBathrooms(modifications.bathrooms.value)
        setLivingRooms(modifications.living_rooms.value)
        setKnotweed(modifications.japanese_knotweed.value)
        switch (modifications.japanese_knotweed.value.id) {
            case 1:
                setKnotweedValue(0)
                break;
            case 2:
                setKnotweedValue(1)
                break;
            case 3:
                setKnotweedValue(81)
                break;
            case 4:
                setKnotweedValue(96)
                break;
            case 5:
                setKnotweedValue(99)
                break;
        }
        setFloodRisk(modifications.flood_risk.value)
        setNewBuild(modifications.new_build_status.value)
        setTenure(modifications.tenure.value)
        setFloors(modifications.floor_number.value)
        let _temp_date = new Date(modifications.last_sold_date.value * 1000).toISOString().slice(0, 10);
        setDate(_temp_date)
        setSoldValue(modifications.last_sold_value.value)
        setPredictedValue(modifications.algorithm_value.value)
        setAlgAccuracy(modifications.algorithm_accuracy.value)
        setTitleNumber(modifications.title_number.value)
        setKitchen(modifications.kitchen.value)
        // Rental values
        if (modifications.rental_info.graph !== "") setGraphB64(modifications.rental_info.graph)
        if (modifications.rental_info.free_text) setRentFreeText(modifications.rental_info.free_text)
        setMarketRent(modifications.rental_info.market)
        setGrossRental(modifications.rental_info.gross)
        setComparableRental(modifications.rental_info.comparable)
        setTransactionPM(modifications.rental_info.transactionsPM)
        setRentalTurnover(modifications.rental_info.turnover)
        setRentalInventory(modifications.rental_info.inventory)
        setRentalDays(modifications.rental_info.days)
        setRentalDemand(modifications.rental_info.demand)
        // Sales values
        if (modifications.sales_info.free_text) setSalesFreeText(modifications.sales_info.free_text)
        setTotalSale(modifications.sales_info.total_sale)
        setAvgSales(modifications.sales_info.avg_sales)
        setSalesTurnover(modifications.sales_info.turnover)
        setSalesInventory(modifications.sales_info.inventory)
        setSalesDays(modifications.sales_info.days)
        setSalesDemand(modifications.sales_info.demand)
        // Additional values
        if (modifications.additional_info.free_text) setAdditionalFreeText(modifications.additional_info.free_text)
        setConservationArea(modifications.additional_info.conservation_area)
        setListedBuildings(modifications.additional_info.listed_building)
        setConstructionMethod(modifications.additional_info.construction_method)
        setCompletionYear(modifications.additional_info.completion_year)
        setTaxBand(modifications.additional_info.tax_band)
        // Reinstatement values
        if (modifications.reinstatement_info.free_text) setReinstatementFreeText(modifications.reinstatement_info.free_text)
        setAreaUnit(modifications.reinstatement_info.area_unit)
        setClearance(modifications.reinstatement_info.clearance)
        setRebuildCost(modifications.reinstatement_info.rebuild_cost)
        setProfFees(modifications.reinstatement_info.professional_fees)
        setExternalWork(modifications.reinstatement_info.external_work)
        setReinstatementTotal(modifications.reinstatement_info.total)
        // General Comments
        if (modifications.additional_comments) setGeneralComment(modifications.additional_comments)
    },[modifications])

    useEffect(() => {
        if (!report) return;
        setNewValue(report.valuation);
        setAsterisk(report.asterisk)
        if (report.appendix !== null  && report.appendix !== "data:application/pdf;base64,") {
            let _base_64 = report.appendix.split(',')[1]
            setPdfBase64(report.appendix)
            setShowPrevious(true)
            setShowUpload(false)
        }
    }, [report])

    useEffect(() => {
        setFiles([])
    }, [reportGeneration]);

    const updateAppendixValue = (value, item) => {
        if (!item) return;
        switch (item.type) {
            case 1:
                switch (item.id) {
                    case 1:
                        setRentFreeText(value)
                        break;
                    case 2:
                        setMarketRent(value)
                        break;
                    case 3:
                        setGrossRental(value)
                        break;
                    case 4:
                        setComparableRental(value)
                        break;
                    case 5:
                        setTransactionPM(value)
                        break;
                    case 6:
                        setRentalTurnover(value)
                        break;
                    case 7:
                        setRentalInventory(value)
                        break;
                    case 8:
                        setRentalDays(value)
                        break;
                    case 9:
                        setRentalDemand(value)
                        break;
                }
                break;
            case 2:
                switch (item.id) {
                    case 1:
                        setSalesFreeText(value)
                        break;
                    case 2:
                        setTotalSale(value)
                        break;
                    case 3:
                        setAvgSales(value)
                        break;
                    case 4:
                        setSalesTurnover(value)
                        break;
                    case 5:
                        setSalesInventory(value)
                        break;
                    case 6:
                        setSalesDays(value)
                        break;
                    case 7:
                        setSalesDemand(value)
                        break;
                }
                break;
            case 3:
                switch (item.id) {
                    case 1:
                        setAdditionalFreeText(value)
                        break;
                    case 2:
                        setConservationArea(value)
                        break;
                    case 3:
                        setListedBuildings(value)
                        break;
                    case 4:
                        setConstructionMethod(value)
                        break;
                    case 5:
                        setCompletionYear(value)
                        break;
                    case 6:
                        setTaxBand(value)
                        break;
                }
                break;
            case 4:
                switch (item.id) {
                    case 1:
                        setReinstatementFreeText(value)
                        break;
                    case 2:
                        setAreaUnit(value)
                        break;
                    case 3:
                        setClearance(value)
                        break;
                    case 4:
                        setRebuildCost(value)
                        break;
                    case 5:
                        setProfFees(value)
                        break;
                    case 6:
                        setExternalWork(value)
                        break;
                    case 7:
                        setReinstatementTotal(value)
                        break;
                }
                break;
            case 5:
                switch (item.id) {
                    case 1:
                        setGeneralComment(value)
                        break;
                }
        }
    }

    const ChangeModificationValue = (title, value) => {
        if (!title) return;
        switch (title) {
            case "Bedrooms:":
                setBedrooms(value)
                break;
            case "Bathrooms:":
                setBathrooms(value)
                break;
            case "Living Rooms:":
                setLivingRooms(value)
                break;
            case "Property Size (sq ft):":
                setSize(value)
                break;
            case "EPC Rating:":
                setEPCrating(value)
                break;
            case "Floors:":
                setFloors(value)
                break;
            case "Japanese Knotweed:":
                switch (value.id) {
                    case 1:
                        setKnotweedValue(0)
                        break;
                    case 2:
                        setKnotweedValue(1)
                        break;
                    case 3:
                        setKnotweedValue(81)
                        break;
                    case 4:
                        setKnotweedValue(96)
                        break;
                    case 5:
                        setKnotweedValue(99)
                        break;
                }
                setKnotweed(value)
                break;
            case "Property Type:":
                setType(value)
                break;
            case "Flood Risk:":
                setFloodRisk(value)
                break;
            case "New Build Status:":
                setNewBuild(value)
                break;
            case "Tenure:":
                setTenure(value)
                break;
            case "Sold for:":
                setSoldValue(value)
                break;
            case "Algorithm prediction:":
                setPredictedValue(value)
                break;
            case "Algorithm accuracy:":
                setAlgAccuracy(value)
                break;
            case "Title number:":
                setTitleNumber(value)
                break;
            case "Kitchen:":
                setKitchen(value)
                break;
        }
    }

    const handleValuationChange = (ev) => {
        if (!ev.target.value) return;
        let value = Number(ev.target.value.replace(/[^0-9.-]+/g,""));
        setNewValue(value);
    }

    function handleAmendClick() {
        if (!report) return;
        if (processing) return;
        setProcessing(true)
        let accuracy = confidence === CONFIDENT ? 85 : 90;
        let unixtime = Date.parse(date)/1000
        let _graph = ""
        if (addGraph) _graph = graphB64
        let data = {
            "overview": overviewComment,
            "comment": remarks,
            "section 15": specialComment,
            "valuation": newValue,
            "accuracy": accuracy,
            "asterisk": asterisk,
            "files": files,
            "reportGeneration": reportGeneration,
            "modifications": {
                "size": size,
                "type": type.name,
                "epc_rating": EPCrating,
                "bedrooms": bedrooms.name,
                "bathrooms": bathrooms.name,
                "living_rooms": livingRooms,
                "japanese_knotweed": knotweedValue,
                "flood_risk": floodRisk.name,
                "new_build_status": newBuild.name,
                "tenure": tenure || null,
                "floors": floors,
                "date": unixtime,
                "sold_value": soldValue,
                "predicted_value": predictedValue,
                "algorithm_accuracy": algAccuracy,
                "title_number": titleNumber,
                "kitchen": kitchen,
                "rental_info": {
                    "graph": _graph,
                    "free_text": rentFreeText,
                    "market": marketRent,
                    "gross": grossRental,
                    "comparable": comparableRental,
                    "transactionsPM": transactionsPM,
                    "turnover": rentalTurnover,
                    "inventory": rentalInventory,
                    "days": rentalDays,
                    "demand": rentalDemand
                },
                "sales_info": {
                    "free_text": salesFreeText,
                    "total_sale": totalSale,
                    "avg_sales": avgSales,
                    "turnover": salesTurnover,
                    "inventory": salesInventory,
                    "days": salesDays,
                    "demand": salesDemand
                },
                "additional_info": {
                    "free_text": additionalFreeText,
                    "conservation_area": conservationArea,
                    "listed_building": listedBuildings,
                    "construction_method": constructionMethod,
                    "completion_year": completionYear,
                    "tax_band": taxBand
                },
                "reinstatement_info": {
                    "free_text": reinstatementFreeText,
                    "area_unit": areaUnit,
                    "clearance": clearance,
                    "rebuild_cost": rebuildCost,
                    "professional_fees": profFees,
                    "external_work": externalWork,
                    "total": reinstatementTotal
                },
                "additional_comments": generalComment,
            }
        }
        let response = val_api.setAmended(report.summary_id, data);
        response.then(
            response => {
                setProcessing(false)
                navigate("/valuer");
            }
        ).catch(
            err => {
                setProcessing(false)
                console.log(err);
                setError(err.response.data.description);
                setShowErrorDialog(true);
            }
        )
    }

    const handlePdfChange = (type) => {
        if (!type) return;
        setFileType(type)
    }

    const handleAsterisk = () => {
        setAsterisk(!asterisk)
    }

    const handleAddGraph = () => {
        setAddGraph(!addGraph)
    }

    const handleAppendix = () => {
        setShowUpload(!showUpload)
    }

    const handleOverviewChange = (ev) => {
        if (!ev) return;
        if (!ev.target.value.length) {
            setOverviewCounter(0)
            setOverviewComment(ev.target.value)
            return;
        }
        if (ev.target.value.length > 3000) return;
        setOverviewCounter(ev.target.value.length)
        setOverviewComment(ev.target.value)
    }

    const handleRemarksChange = (ev) => {
        if (!ev) return;
        if (!ev.target.value.length) {
            setRemarksCounter(0)
            setRemarks(ev.target.value)
            return;
        }
        if (ev.target.value.length > 3000) return;
        setRemarksCounter(ev.target.value.length)
        setRemarks(ev.target.value)
    }

    const handleSpecialChange = (ev) => {
        if (!ev) return;
        if (!ev.target.value.length) {
            setSpecialCounter(0)
            setSpecialComment(ev.target.value)
            return;
        }
        if (ev.target.value.length > 3000) return;
        setSpecialCounter(ev.target.value.length)
        setSpecialComment(ev.target.value)
    }

    const handleDateChange = (ev) => {
        if (!ev) return;
        setDate(ev.target.value)
    }

    const updateFiles = (files) => {
        setFiles(files)
    }

    const handleOKDialogResult = (res) => {
        setShowErrorDialog(false);
    }

    useEffect(() => {
        if (showUpload) {
            setPdfBase64("")
        } else {
            if (!report) return;
            if (report.appendix !== null  && report.appendix !== "data:application/pdf;base64,") {
                setPdfBase64(report.appendix)
            }
        }
    },[showUpload])

    useEffect(() => {
        let _clearance = 0
        let _rebuildCost = 0
        let _profFees = 0
        let _externalWork = 0
        if (clearance) _clearance = clearance;
        if (rebuildCost) _rebuildCost = rebuildCost;
        if (profFees) _profFees = profFees;
        if (externalWork) _externalWork = externalWork;
        let _total = _clearance + _rebuildCost + _profFees + _externalWork
        setReinstatementTotal(_total)
    }, [clearance, rebuildCost, profFees, externalWork]);


    return (
        <>
            {modifications ?
                <div className="valuer-amend-container">
                    <div className="valuer-amend-valuation">
                        <div className="valuer-amend-header-container">
                            <div className="valuer-amend-label">Valuation</div>
                            <div className="valuer-amend-valuation-text">
                                <input type="text" value={newValue && "£" + newValue.toLocaleString()}
                                       onChange={handleValuationChange}/>
                            </div>
                        </div>
                        <div className="valuer-radio-container">
                            <div className="valuer-radio-button-container link" onClick={handleAsterisk}>
                                {asterisk ?
                                    <div className="valuer-radio-point link"/>
                                    :null}
                            </div>
                            <div className="valuer-asterisk-text">Add asterisk to market value</div>
                        </div>
                    </div>
                    <div className="valuer-amend-comment remarks">
                        <div className="valuer-amend-label">Overview remarks ({overviewCounter}/3000)</div>
                        <div className="valuer-amend-comment-text">
                            <textarea type="text" value={overviewComment}
                                      onChange={(ev) => handleOverviewChange(ev)}/>
                        </div>
                    </div>
                    <div className="valuer-amend-modifications_container">
                        <div className="valuer-amend-property-row">
                            <ValuerAmendIntCard title={"Property Size (sq ft):"} data={size}
                                                changeValue={ChangeModificationValue.bind(this)}/>
                            <ValuerAmendIntCard title={"EPC Rating:"} data={EPCrating}
                                                changeValue={ChangeModificationValue.bind(this)}/>
                            <ValuerAmendDropdownCard title={"Bedrooms:"} data={bedrooms}
                                                     options={bed_bath_options}
                                                     changeValue={ChangeModificationValue.bind(this)}/>
                            <ValuerAmendDropdownCard title={"Bathrooms:"} data={bathrooms}
                                                     options={bed_bath_options}
                                                     changeValue={ChangeModificationValue.bind(this)}/>
                        </div>
                        <div className="valuer-amend-property-row">
                            <ValuerAmendIntCard title={"Living Rooms:"} data={livingRooms}
                                                changeValue={ChangeModificationValue.bind(this)}/>
                            <ValuerAmendDropdownCard title={"Japanese Knotweed:"} data={knotweed}
                                                     options={knotweed_options}
                                                     changeValue={ChangeModificationValue.bind(this)}/>
                            <ValuerAmendDropdownCard title={"New Build Status:"} data={newBuild}
                                                     options={build_options}
                                                     changeValue={ChangeModificationValue.bind(this)}/>
                            <ValuerAmendIntCard title={"Floors:"} data={floors}
                                                changeValue={ChangeModificationValue.bind(this)}/>
                        </div>
                        <div className="valuer-amend-property-row">
                            <div className="valuer-amend-card-container" >
                                <div className="valuer-amend-card-header-container">
                                    <div className="valuer-amend-card-header">
                                        Sale date:
                                    </div>
                                </div>
                                <input type="date" className="valuer-amend-date" value={date} onChange={(ev) => handleDateChange(ev)}/>
                            </div>
                            <ValuerAmendCurrencyCard title={"Sold for:"} data={soldValue}
                                                     changeValue={ChangeModificationValue.bind(this)}/>
                            <ValuerAmendCurrencyCard title={"Algorithm prediction:"} data={predictedValue}
                                                     changeValue={ChangeModificationValue.bind(this)}/>
                            <ValuerAmendIntCard title={"Algorithm accuracy:"} data={algAccuracy}
                                                changeValue={ChangeModificationValue.bind(this)}/>
                        </div>
                    </div>
                    <div className="valuer-amend-accuracy">
                        <div className="valuer-amend-accuracy-heading">How confident are you in your decision?</div>
                        <input type="radio" id="confident" value={CONFIDENT} name="accuracy"
                               checked={confidence === CONFIDENT}
                               onChange={() => setConfidence(CONFIDENT)}/>
                        <label htmlFor="confident">Confident</label>
                        <input type="radio" id="very-confident" value={VERY_CONFIDENT} name="accuracy"
                               checked={confidence === VERY_CONFIDENT}
                               onChange={() => setConfidence(VERY_CONFIDENT)}/>
                        <label htmlFor="very-confident">Very confident</label>
                    </div>
                    <div className="valuer-amend-comment remarks">
                        <div className="valuer-amend-label">Section 13 Remarks ({remarksCounter}/3000)</div>
                        <div className="valuer-amend-comment-text">
                            <textarea type="text" value={remarks}
                                      onChange={(ev) => handleRemarksChange(ev)}/>
                        </div>
                    </div>
                    <div className="valuer-amend-comment remarks">
                        <div className="valuer-amend-label">
                            Section 15 Market value with special assumptions of restricted marketing period ({specialCounter}/3000)
                        </div>
                        <div className="valuer-amend-comment-text">
                            <textarea type="text" value={specialComment}
                                      onChange={(ev) => handleSpecialChange(ev)}/>
                        </div>
                    </div>
                    <ValuerReviewAppendix floodRisk={floodRisk}
                                          titleNumber={titleNumber}
                                          kitchen={kitchen}
                                          tenure={tenure}
                                          type={type}
                                          changeModification={ChangeModificationValue.bind(this)}
                                          graph={graphB64}
                                          addGrapgh={addGraph}
                                          rental_info={rental_info}
                                          sales_info={sales_info}
                                          additional_info={additional_info}
                                          reinstatement_info={reinstatement_info}
                                          generalComment={generalComment}
                                          files={files}
                                          update={updateAppendixValue.bind(this)}
                                          updateFiles={updateFiles.bind(this)}
                                          updateGraph={handleAddGraph.bind(this)}
                                          reportGeneration={reportGeneration}
                    />
                    <div className="valuer-amend-button-container">
                        <button className={processing ? "valuer-amend-button" : "valuer-amend-button link"} onClick={handleAmendClick}
                                disabled={!newValue}>
                            {processing ?
                                <div className="valuer-submit-container">
                                    <ButtonLoadingWheel show={processing}/>
                                </div>
                                :
                                <>Submit to client</>
                            }
                        </button>
                    </div>
                    {showErrorDialog
                        ? <GenericDialog title="Report generation error" text={error} buttons={okButton}
                                         result={handleOKDialogResult.bind(this)} />
                        : null
                    }
                </div>
                :null}
        </>
    )
}

export default ValuerReviewAmend