import close from "../assets/Close.png";
const AddressLookupFlyout = (props) => {
    const hide = props.hide;
    return (
        <>
            <div className="address-flyout-container closed" id="address-flyout-container">
                <div className="address-flyout-header">
                    <div className="address-flyout-header-text">Address finder</div>
                    <div className="address-flyout-close link" onClick={() => hide("address-flyout-container")}>
                        <img src={close} alt={"Close Tab"}/>
                    </div>
                </div>
                <div className="address-flyout-content">
                    <p>Our valuation model currently covers around 21 million addresses in England and Wales.</p>
                    <p>
                        Our valuation model currently does not cover properties that are excluded from the HMLR
                        database.
                    </p>
                    <p>This includes:</p>

                    <p>
                        <ul>
                            <li>sales that have not been lodged with HM Land Registry</li>
                            <li>sales that were not for value</li>
                            <li>
                                transfers, conveyances, assignments or leases at a premium with nominal
                                rent, which are:
                            </li>
                            <div>
                                <ul>
                                    <li>‘Right to buy’ sales at a discount</li>
                                    <li>subject to an existing mortgage</li>
                                    <li>
                                        to effect the sale of a share in a property, for example, a transfer between
                                        parties on divorce
                                    </li>
                                    <li>by way of a gift</li>
                                    <li>under a compulsory purchase order</li>
                                    <li>under a court order</li>
                                    <li>to Trustees appointed under Deed of appointment</li>
                                    <li>Vesting Deeds Transmissions or Assents of more than one property</li>
                                </ul>
                            </div>
                        </ul>

                    </p>

                    <p>
                        Our model also excludes properties that have an expired or invalid Energy
                        Performance Certificate.
                    </p>
                </div>

            </div>
        </>
    )
}

export default AddressLookupFlyout