import arrow from "../../assets/arrow_back.png";
import dateUtils from "../../utils/DateUtils";
import {useEffect} from "react";
const ValuerChangeLog = (props) => {
    const address = props.address;
    const history = props.history;
    const clickBack = props.clickBack;

    function handleBackClick() {
        clickBack();
    }

    return (
        <>
            <div className="change-log-container">
                <div className="change-log-title">
                    Valuation Check
                </div>
                <div className="change-log-content">
                    <div className="change-log-back-container">
                        <div className="change-log-back-arrow link" onClick={handleBackClick}>
                            <img src={arrow} alt="back arrow logo" />
                        </div>
                        <div className="change-log-back-text">Change log</div>
                    </div>
                    <div className="change-log-address">
                        {address}
                    </div>
                    <div className="change-log-grid-container">
                        <div className="change-log-grid-heading">
                            <div className="action col">Action</div>
                            <div className="user col">Who</div>
                            <div className="date col">Date/Time</div>
                        </div>
                        <div className="change-log-grid-rows">
                            {history && history.map((entry, idx) => {
                                return (
                                    <div className="change-log-grid-row">
                                        <div className="action col">{entry.comment}</div>
                                        <div className="user col">{entry.entry_user.firstname.substring(0,1)}. {entry.entry_user.lastname}</div>
                                        <div className="date col">{dateUtils.display_date_time(entry.entry_date*1000)}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default ValuerChangeLog